import { tempStorage } from 'app/index';
import {
  LoginResponse,
  UserInfoResponse,
  defaultLoginResponse,
  defaultUserInfoResponse,
} from './models';
import {
  consistencyType,
  defaultUser,
  getUserLocalStorage,
  testErrorType,
  updateUserLocalStorage,
} from './localStorageHelper';
import {
  FetchAllDistancesResponse,
  FetchCommonResponse,
  FetchGenerateReportResponse,
  FetchGetAllTermsResponse,
  FetchGetConsistencyListResponse,
  FetchGetTemplatesListResponse,
  FetchGetTermResponse,
  FetchParallelConsistencyResponse,
  FetchReferencesResponse,
  FetchRootsResponse,
  FetchSearchStemmedResponse,
  FetchTemplateDocumentContentResponse,
  FetchTermLookupResponse,
  FetchTermsDescriptionsResponse,
  FetchUpdateTermResponse,
  defaultFetchAllDistancesResponse,
  defaultFetchCommonResponse,
  defaultFetchGenerateReportResponse,
  defaultFetchGetAllTermsResponse,
  defaultFetchGetConsistencyListResponse,
  defaultFetchGetTemplatesListResponse,
  defaultFetchGetTermResponse,
  defaultFetchParallelConsistencyResponse,
  defaultFetchReferencesResponse,
  defaultFetchRootsResponse,
  defaultFetchSearchStemmedResponse,
  defaultFetchTemplateDocumentContentResponse,
  defaultFetchTermLookupResponse,
  defaultFetchTermsDescriptionsResponse,
  defaultFetchUpdateTermResponse,
} from './apiModels';

const DISTANCES_FOUND_PERCENT_THRESHOLD: number = 0.95;

const fetchGetTemplatesList = async () => {
  let resp: FetchGetTemplatesListResponse =
    defaultFetchGetTemplatesListResponse;

  const response = await authorizedFetch('GET', '/api/templates', '');

  if (response.ok) {
    const responseObject: FetchGetTemplatesListResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchTemplateDocumentContent = async (templateName: string) => {
  if (templateName) {
    templateName = '/' + templateName;
  }
  let resp: FetchTemplateDocumentContentResponse =
    defaultFetchTemplateDocumentContentResponse;
  const templateDocumentUrl =
    '/api/template' + templateName + '?' + Math.floor(Math.random() * 1000);

  let base64Content: string = '';
  const response = await authorizedFetch('GET', templateDocumentUrl, '');
  resp.httpCode = response.status;
  if (response.ok) {
    const data = await response.arrayBuffer();
    base64Content = btoa(
      new Uint8Array(data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
    resp.template = base64Content;
  } else {
    resp.error = 'response error code:' + response.status;
    console.error(resp.error);
  }

  return resp;
};

const fetchDistances = async (main: string, secondary: string[]) => {
  const body = JSON.stringify({ main: main, secondary: secondary });
  let result: FetchAllDistancesResponse = {
    ...defaultFetchAllDistancesResponse,
  };

  const response = await authorizedFetch('POST', '/api/calcdist', body);

  if (response.ok) {
    const responseObject: FetchAllDistancesResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error = 'response error code:' + response.status;
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

const fetchAllDistances = async (
  paragraphs: string[],
  descriptions: string[]
) => {
  const body = JSON.stringify({
    paragraphs: paragraphs,
    descriptions: descriptions,
  });
  let result: FetchAllDistancesResponse = {
    ...defaultFetchAllDistancesResponse,
  };

  const response = await authorizedFetch('POST', '/api/calcalldist', body);

  if (response.ok) {
    const responseObject: FetchAllDistancesResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error = 'response error code:' + response.status;
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

const fetchTermsDescriptions = async (inputText: string) => {
  const body = JSON.stringify({
    Paragraphs: [inputText],
    ExcludeTerms: tempStorage.totalTerms,
    IncludeSource: false,
  });
  let resp: FetchTermsDescriptionsResponse =
    defaultFetchTermsDescriptionsResponse;

  const response = await authorizedFetch('POST', '/api/termsfill', body);

  if (response.ok) {
    const responseObject: FetchTermsDescriptionsResponse =
      await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
      tempStorage.totalTerms = responseObject.totalTerms;
      tempStorage.origTerms = responseObject.origTerms;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchTermLookup = async (paragraphs: string[], term: string) => {
  const body = JSON.stringify({
    Paragraphs: paragraphs,
    Term: term,
  });
  let result: FetchTermLookupResponse = { ...defaultFetchTermLookupResponse };

  const response = await authorizedFetch('POST', '/api/termlookup', body);

  if (response.ok) {
    const responseObject: FetchTermLookupResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      result.error = 'response object error';
      if (responseObject) {
        result.error = responseObject.error;
      }
      console.error(result.error);
    } else {
      result = responseObject;
    }
  } else {
    result.error =
      'response error code:' + response.status + ':' + (await response.text());
    console.error(result.error);
  }

  result.httpCode = response.status;

  return result;
};

const fetchGetAllTerms = async () => {
  let resp: FetchGetAllTermsResponse = { ...defaultFetchGetAllTermsResponse };

  const response = await authorizedFetch('GET', '/api/terms', '');

  if (response.ok) {
    const responseObject: FetchGetAllTermsResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchGetTermsFiltered = async (keyword: string) => {
  let resp: FetchGetAllTermsResponse = { ...defaultFetchGetAllTermsResponse };

  const response = await authorizedFetch(
    'GET',
    '/api/termsfilter/' + keyword,
    ''
  );

  if (response.ok) {
    const responseObject: FetchGetAllTermsResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchGetTerm = async (term: string) => {
  let resp: FetchGetTermResponse = { ...defaultFetchGetTermResponse };

  const response = await authorizedFetch('GET', '/api/terms/' + term, '');

  if (response.ok) {
    const responseObject: FetchGetTermResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchUpdateDescription = async (term: string, description: string) => {
  const body = JSON.stringify({
    Term: term,
    Description: description,
  });

  let resp: FetchUpdateTermResponse = { ...defaultFetchUpdateTermResponse };

  const response = await authorizedFetch('PUT', '/api/term', body);

  if (response.ok) {
    const responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchReferences = async (text: string) => {
  const resp: FetchReferencesResponse = { ...defaultFetchReferencesResponse };
  const commonResp: FetchCommonResponse = await fetchCommon(
    text,
    'references',
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.references = commonResp.data.split(',').map((item) => item.trim());
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetchReferences error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

const fetchSearchStemmed = async (
  paragraphs: string[],
  keywords: string[],
  roots: string[]
) => {
  let resp: FetchSearchStemmedResponse = {
    ...defaultFetchSearchStemmedResponse,
  };
  const body = JSON.stringify({
    paragraphs: paragraphs,
    keywords: keywords,
    roots: roots,
  });

  const response = await authorizedFetch('POST', '/api/searchstem', body);

  if (response.ok) {
    const responseObject: FetchSearchStemmedResponse = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  resp.httpCode = response.status;

  return resp;
};

const fetchTestSingleClaim = async (claim: string, testName: string) => {
  const resp: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const filteredClaim = claim.replaceAll('\t', ' ');

  const commonResp: FetchCommonResponse = await fetchConsistency(
    filteredClaim,
    testName,
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.data = commonResp.data;
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetch test claims error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

const fetchParallelTestClaims = async (claims: string[], testName: string) => {
  const resp: FetchParallelConsistencyResponse = {
    ...defaultFetchParallelConsistencyResponse,
  };

  const filteredClaims: string[] = [];
  for (let i = 0; i < claims.length; i++) {
    const filteredClaim = claims[i].replaceAll('\t', ' ');
    filteredClaims.push(filteredClaim);
  }

  const parResp: FetchParallelConsistencyResponse =
    await fetchParallelClaimsConsistency(
      filteredClaims,
      testName,
      'gpt',
      false
    );

  if (parResp) {
    if (!parResp.error) {
      resp.items = [];
      parResp.items.forEach((item) => {
        resp.items.push(item);
      });
    } else {
      resp.error = parResp.error;
    }
  } else {
    resp.error = 'fetch test claims error';
  }

  resp.httpCode = parResp.httpCode;

  return resp;
};

const fetchFigure = async (
  figureTitle: string,
  figureDescription: string,
  claims: string[]
) => {
  const resp: FetchCommonResponse = { ...defaultFetchCommonResponse };

  let text = 'Figure title: "' + figureTitle + '"\n';
  text += 'Figure description: "' + figureDescription + '"\n';
  text += 'Claims:\n';
  for (let i = 0; i < claims.length; i++) {
    text += i.toString() + '. ' + claims[i] + '\n';
  }

  const commonResp: FetchCommonResponse = await fetchCommon(
    text,
    'figure',
    'gpt',
    false
  );

  if (commonResp) {
    if (!commonResp.error) {
      resp.data = commonResp.data;
    } else {
      resp.error = commonResp.error;
    }
  } else {
    resp.error = 'fetch figure error';
  }

  resp.httpCode = commonResp.httpCode;

  return resp;
};

const fetchCommon = async (
  data: string,
  instructionType: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    data: data,
    instructionType: instructionType,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/common', body);

  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchGetConsistencyList = async () => {
  let resp: FetchGetConsistencyListResponse =
    defaultFetchGetConsistencyListResponse;

  const response = await authorizedFetch(
    'GET',
    '/api/consitems/' + tempStorage.apiLanguage,
    ''
  );

  if (response.ok) {
    const responseObject: FetchGetConsistencyListResponse =
      await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      resp = responseObject;
    }
  } else {
    console.error(
      'response error code:' + response.status + ':' + (await response.text())
    );
  }

  return resp;
};

const fetchConsistency = async (
  data: string,
  testKey: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    data: data,
    testKey: testKey,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/consistency', body);

  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchParallelClaimsConsistency = async (
  claims: string[],
  testKey: string,
  modelType: string,
  test: boolean
) => {
  const body = JSON.stringify({
    claims: claims,
    testKey: testKey,
    modelType: modelType,
    test: test,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/parcons', body);

  let responseObject: FetchParallelConsistencyResponse = {
    ...defaultFetchParallelConsistencyResponse,
  };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.items);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchRoots = async (lines: string[]) => {
  if (tempStorage.apiLanguage === 'en') {
    for (let i = 0; i < lines.length; i++) {
      lines[i] = 'a ' + lines[i];
    }
  }

  const body = JSON.stringify({
    lines: lines,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/roots', body);

  let responseObject: FetchRootsResponse = { ...defaultFetchRootsResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log(responseObject.roots);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchDetailedClaim = async (
  inputClaim: string,
  claimId: number,
  useGpt: boolean,
  test: boolean
) => {
  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const body = JSON.stringify({
    data: inputClaim,
    claimId: claimId,
    useGpt: useGpt,
    language: tempStorage.apiLanguage,
    test: test,
  });

  const response = await authorizedFetch('POST', '/api/claim', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchLogin = async (login: string, password: string) => {
  const body = JSON.stringify({
    login: login,
    password: password,
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  };

  console.log('input body:' + body);

  const response = await fetch(
    process.env.PATENT_SERVER_BASE_ADDRESS + '/api/login',
    options
  );
  console.log(
    'fetchLogin finished via host: ' + process.env.PATENT_SERVER_BASE_ADDRESS
  );

  let responseObject: LoginResponse = { ...defaultLoginResponse };

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserAllTokens(responseObject);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchRefreshToken = async () => {
  let responseObject: LoginResponse = { ...defaultLoginResponse };
  const currentUser = getUserLocalStorage();

  if (!isRefreshTokenValid || !currentUser) {
    return responseObject;
  }

  const body = JSON.stringify({
    refreshToken: currentUser.refreshToken,
  });

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  };

  console.log('input body:' + body);

  const response = await fetch(
    process.env.PATENT_SERVER_BASE_ADDRESS + '/api/refresh',
    options
  );
  console.log(
    'fetchRefreshToken finished via host: ' +
      process.env.PATENT_SERVER_BASE_ADDRESS
  );

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserAccess(
        responseObject.accessToken,
        responseObject.expiresIn,
        responseObject.backend
      );
    }
  } else {
    responseObject.error = response.statusText;
    console.error('fetchRefreshToken response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchSetToken = async (expiresIn: number) => {
  let responseObject: FetchCommonResponse = { ...defaultFetchCommonResponse };

  const body = JSON.stringify({
    expiresIn: expiresIn,
  });

  const response = await authorizedFetch('POST', '/api/settoken', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.data);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchUserInfo = async () => {
  let responseObject: UserInfoResponse = { ...defaultUserInfoResponse };
  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return responseObject;
  }

  const response = await authorizedFetch('GET', '/api/userinfo', '');

  console.log('fetchUserInfo finished via host: ' + +currentUser.backend);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response login json done');

    if (!responseObject || responseObject.error) {
      console.error('response login object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.statusMessage);
      updateCurrentUserInfo(responseObject);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  responseObject.httpCode = response.status;

  return responseObject;
};

const fetchGenerateReport = async (
  tests: consistencyType[],
  title: string,
  dateTime: string
) => {
  let responseObject: FetchGenerateReportResponse =
    defaultFetchGenerateReportResponse;

  const body = JSON.stringify({
    tests: tests,
    title: title,
    dateTime: dateTime,
    language: tempStorage.apiLanguage,
  });

  const response = await authorizedFetch('POST', '/api/report', body);

  if (response.ok) {
    responseObject = await response.json();
    console.log('response json done');

    if (!responseObject || responseObject.error) {
      console.error('response object error:' + responseObject.error);
    } else {
      console.log('fetch result:' + responseObject.reportId);
    }
  } else {
    responseObject.error = response.statusText;
    console.error('response error code:' + response.status);
  }

  return responseObject;
};

/**
 * Generic authorized fetch wrapper
 * @param method // GET, POST
 * @param path // part of url after host:port with trailing slash
 * @param body // JSON encoded body for POST method
 * @returns
 */
const authorizedFetch = async (method: string, path: string, body: string) => {
  let response: Response = null;

  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return response;
  }

  const options: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${currentUser.accessToken}`,
    },
  };

  console.log('input body:' + body);

  if (body) {
    options.body = body;
  }

  response = await fetch(currentUser.backend + path, options);
  console.log('fetch finished for: ' + currentUser.backend + path);

  if (response.ok) {
    console.log('response ok');
  } else {
    if (response.status === 401) {
      const refreshResult = await fetchRefreshToken();
      if (refreshResult.accessToken) {
        await fetchSetToken(refreshResult.expiresIn);
        options.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshResult.accessToken}`,
        };

        response = await fetch(currentUser.backend + path, options);
        console.log('fetch2 finished for: ' + currentUser.backend + path);
      }
    }
  }

  return response;
};

const updateCurrentUserInfo = (responseObject: UserInfoResponse) => {
  const currentUser = getUserLocalStorage();
  if (!currentUser) {
    return false;
  }
  currentUser.email = responseObject.email;
  currentUser.emailVerified = responseObject.emailVerified;
  currentUser.givenName = responseObject.givenName;
  currentUser.familyName = responseObject.familyName;
  currentUser.preferredUsername = responseObject.preferredUsername;
  currentUser.sub = responseObject.sub;
  currentUser.backend = responseObject.backend;
  tempStorage.backend = currentUser.backend;
  updateUserLocalStorage(currentUser);
  console.log('User info updated with backend: ' + tempStorage.backend);
  return true;
};

const updateCurrentUserAllTokens = (responseObject: LoginResponse) => {
  const currentUser = getUserLocalStorage();
  currentUser.accessToken = responseObject.accessToken;
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  currentUser.expiresAt = unixTimeStampInSeconds + responseObject.expiresIn;
  currentUser.refreshToken = responseObject.refreshToken;
  currentUser.refreshExpiresAt =
    unixTimeStampInSeconds + responseObject.refreshExpiresIn;

  currentUser.backend = responseObject.backend;
  tempStorage.backend = currentUser.backend;
  updateUserLocalStorage(currentUser);
  console.log('User all tokens updated with backend: ' + tempStorage.backend);
};

const isAccessTokenValid = async () => {
  const currentUser = getUserLocalStorage();
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  let isValid = false;
  if (
    currentUser &&
    currentUser.accessToken &&
    currentUser.expiresAt > unixTimeStampInSeconds
  ) {
    isValid = true;
  } else {
    if (!currentUser) {
      updateUserLocalStorage(defaultUser);
    }
    const refreshResult = await fetchRefreshToken();
    if (refreshResult.accessToken) {
      isValid = true;
      await fetchSetToken(refreshResult.expiresIn);
    }
  }

  return isValid;
};

const isRefreshTokenValid = () => {
  const currentUser = getUserLocalStorage();
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  let isValid = false;
  if (
    currentUser &&
    currentUser.refreshToken &&
    currentUser.refreshExpiresAt > unixTimeStampInSeconds
  ) {
    isValid = true;
  }

  return isValid;
};

const updateCurrentUserAccess = (
  accessToken: string,
  expiresIn: number,
  backend: string
) => {
  let currentUser = getUserLocalStorage();
  if (!currentUser) {
    currentUser = { ...defaultUser };
  }

  currentUser.accessToken = accessToken;
  const unixTimeStampInSeconds = Math.floor(Date.now() / 1000);
  currentUser.expiresAt = unixTimeStampInSeconds + expiresIn;
  currentUser.backend = backend;
  tempStorage.backend = currentUser.backend;

  updateUserLocalStorage(currentUser);
  console.log('User access updated with backend: ' + tempStorage.backend);
};

export {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchGetTemplatesList,
  fetchTemplateDocumentContent,
  fetchDistances,
  fetchAllDistances,
  fetchTermsDescriptions,
  fetchUpdateDescription,
  fetchCommon,
  fetchGetConsistencyList,
  fetchConsistency,
  fetchParallelClaimsConsistency,
  fetchRoots,
  fetchDetailedClaim,
  fetchTermLookup,
  fetchGetAllTerms,
  fetchGetTermsFiltered,
  fetchGetTerm,
  fetchReferences,
  fetchSearchStemmed,
  fetchFigure,
  fetchTestSingleClaim,
  fetchParallelTestClaims,
  fetchLogin,
  fetchRefreshToken,
  fetchUserInfo,
  fetchSetToken,
  fetchGenerateReport,
  isAccessTokenValid,
};
