import { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useResolvedPath,
} from 'react-router-dom';
import { Divider } from '@fluentui/react-components';

import { initDocumentWithTemplate } from 'app/office-document';
import { fetchGetTemplatesList } from 'engine/apiHelpers';
import { RouterContextType } from 'views/App/App';
import { defaultStatus } from 'app/index';
import * as classes from 'views/Template/Template.scss';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import { UIStrings } from 'app/UIStrings';

const Template = () => {
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const resolved = useResolvedPath(`/index.html/patent-formula`);
  const { status, setStatus }: RouterContextType = useOutletContext();

  useEffect(() => {
    fetchGetTemplatesList().then((response) => {
      if (response.httpCode === 401) {
        return navigate('/index.html', { replace: true });
      }

      setTemplates(response.templates);
      setIsLoading(false);
    });
  }, []);

  // const searchInput = useRef<HTMLInputElement>(null);
  // const [searchTerm, setSearchTerm] = useState<string>('');

  const selectTemplate = async (templateName: string, language: string) => {
    await initDocumentWithTemplate(templateName, language).then(() => {
      setStatus(defaultStatus);
    });
  };

  // const handleInputClick = () => {
  //   searchInput.current.focus();
  // };

  // const handleKeyClick = (event: KeyboardEvent<HTMLDivElement>) => {
  //   if (event.key === 'Escape') {
  //     setSearchTerm('');
  //   }
  // };

  return (
    <div>
      {isLoading && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.templates.template_title}
          description={UIStrings.templates.template_description}
          color="white"
        />

        {/* <div className={classes.searchContainer}>
        <div
          className={classes.searchInputWrapper}
          onClick={handleInputClick}
          onKeyDown={handleKeyClick}
        >
          <div className={`${classes.icon} ${classes.searchIcon}`}></div>
          <input
            className={classes.searchInput}
            placeholder="Suchen…"
            ref={searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div> */}

        <Link
          to={resolved.pathname}
          className={classes.link}
          style={{ width: '298px' }}
        >
          <Button
            title={UIStrings.templates.continue}
            color="white"
            size="big"
          />
        </Link>

        <Divider className={classes.divider}>
          {UIStrings.templates.choose_template}
        </Divider>

        {templates.length > 0 && (
          <div className={classes.templates}>
            {templates.map((template) => (
              <Link
                key={template.name}
                to={resolved.pathname}
                onClick={() => selectTemplate(template.name, template.language)}
                className={classes.link}
              >
                <div className={classes.templateItem}>
                  <div className={classes.title}>
                    <div>{template.title}</div>
                    <div
                      className={`${classes.icon} ${classes.arrowRightIcon}`}
                    ></div>
                  </div>
                  <div className={classes.description}>
                    <span>{template.description}</span>
                    <span className={classes.language}>
                      {template.language.toUpperCase()}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Template;
