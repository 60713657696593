import { ConsistencyInstructionItem } from "./models";

interface FetchTemplateDocumentContentResponse {
  template: string;
  httpCode: number;
  error: string;
}

const defaultFetchTemplateDocumentContentResponse: FetchTemplateDocumentContentResponse =
{
  template: '',
  httpCode: 0,
  error: '',
};

interface FetchTermsDescriptionsResponse {
  paragraphs: string[];
  usedTerms: string[];
  totalTerms: string[];
  origTerms: string[];
  httpCode: number;
  error: string;
}

const defaultFetchTermsDescriptionsResponse: FetchTermsDescriptionsResponse = {
  paragraphs: [],
  usedTerms: [],
  totalTerms: [],
  origTerms: [],
  error: '',
  httpCode: 0,
};

interface FetchTermLookupResponse {
  stemmedText: string;
  stemmedTerm: string;
  text: string;
  index: number;
  httpCode: number;
  error: string;
}

const defaultFetchTermLookupResponse: FetchTermLookupResponse = {
  stemmedText: '',
  stemmedTerm: '',
  text: '',
  index: -1,
  error: '',
  httpCode: 0,
};

interface TermDefinition {
  term: string;
  description: string;
}

interface FetchGetAllTermsResponse {
  definitions: TermDefinition[];
  httpCode: number;
  error: string;
}

const defaultFetchGetAllTermsResponse: FetchGetAllTermsResponse = {
  definitions: [],
  error: '',
  httpCode: 0,
};

interface Template {
  name: string;
  title: string;
  description: string;
  language: string;
}

interface FetchGetTemplatesListResponse {
  templates: Template[];
  httpCode: number;
  error: string;
}

const defaultFetchGetTemplatesListResponse: FetchGetTemplatesListResponse = {
  templates: [],
  httpCode: 0,
  error: '',
};

interface FetchGetTermResponse {
  term: string;
  description: string;
  httpCode: number;
  error: string;
}

const defaultFetchGetTermResponse: FetchGetTermResponse = {
  term: '',
  description: '',
  httpCode: 0,
  error: '',
};

interface FetchUpdateTermResponse {
  id: number;
  term: string;
  description: string;
  httpCode: number;
  error: string;
}

const defaultFetchUpdateTermResponse: FetchUpdateTermResponse = {
  id: 0,
  term: '',
  description: '',
  httpCode: 0,
  error: '',
};

interface FetchAllDistancesResponse {
  distances: number[];
  indices: number[];
  percentage: number[];
  httpCode: number;
  error: string;
}

const defaultFetchAllDistancesResponse: FetchAllDistancesResponse = {
  distances: [],
  indices: [],
  percentage: [],
  httpCode: 0,
  error: '',
};

interface FetchCommonResponse {
  data: string;
  httpCode: number;
  error: string;
}

const defaultFetchCommonResponse: FetchCommonResponse = {
  data: '',
  httpCode: 0,
  error: '',
};

interface FetchParallelConsistencyResponse {
  items: string[];
  httpCode: number;
  error: string;
}

const defaultFetchParallelConsistencyResponse: FetchParallelConsistencyResponse = {
  items: [],
  httpCode: 0,
  error: '',
};

interface FetchGetConsistencyListResponse {
  items: ConsistencyInstructionItem[];
  error: string;
}

const defaultFetchGetConsistencyListResponse: FetchGetConsistencyListResponse =
{
  items: [],
  error: '',
};

interface FetchRootsResponse {
  roots: string[];
  httpCode: number;
  error: string;
}

const defaultFetchRootsResponse: FetchRootsResponse = {
  roots: [],
  httpCode: 0,
  error: '',
};

interface FetchReferencesResponse {
  references: string[];
  httpCode: number;
  error: string;
}

const defaultFetchReferencesResponse: FetchReferencesResponse = {
  references: [],
  httpCode: 0,
  error: '',
};

interface SearchResult {
  startIndex: number;
  endIndex: number;
  word: string;
  keyword: string;
  stemmedKeyword: string;
}

interface ParagraphSearchResults {
  parIndex: number;
  searchResults: SearchResult[];
}

interface FetchSearchStemmedResponse {
  paragraphs: ParagraphSearchResults[];
  httpCode: number;
  error: string;
}

const defaultFetchSearchStemmedResponse: FetchSearchStemmedResponse = {
  paragraphs: [],
  httpCode: 0,
  error: '',
};

interface FetchGenerateReportResponse {
  reportId: string;
  error: string;
}

const defaultFetchGenerateReportResponse: FetchGenerateReportResponse = {
  reportId: '',
  error: '',
};

export {
  FetchTemplateDocumentContentResponse,
  defaultFetchTemplateDocumentContentResponse,
  FetchTermsDescriptionsResponse,
  defaultFetchTermsDescriptionsResponse,
  FetchTermLookupResponse,
  defaultFetchTermLookupResponse,
  FetchGetAllTermsResponse,
  defaultFetchGetAllTermsResponse,
  FetchGetTemplatesListResponse,
  defaultFetchGetTemplatesListResponse,
  FetchGetTermResponse,
  defaultFetchGetTermResponse,
  FetchUpdateTermResponse,
  defaultFetchUpdateTermResponse,
  FetchAllDistancesResponse,
  defaultFetchAllDistancesResponse,
  FetchCommonResponse,
  defaultFetchCommonResponse,
  FetchParallelConsistencyResponse,
  defaultFetchParallelConsistencyResponse,
  FetchGetConsistencyListResponse,
  defaultFetchGetConsistencyListResponse,
  FetchRootsResponse,
  defaultFetchRootsResponse,
  FetchReferencesResponse,
  defaultFetchReferencesResponse,
  FetchSearchStemmedResponse,
  defaultFetchSearchStemmedResponse,
  FetchGenerateReportResponse,
  defaultFetchGenerateReportResponse,
};
