import { useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { abstractType } from 'engine/localStorageHelper';
import * as classes from 'components/AbstractItem/AbstractItem.scss';
import InputError from 'components/InputError/InputError';

type AbstractItemProps = {
  abstract: abstractType;
  isNew?: boolean;
  cancel?: () => void;
  createCustomAbstract?: (title: string) => void;
  deleteAbstract?: (abstract: abstractType) => void;
  updateAbstractInLocalStorage?: (
    abstract: abstractType,
    newTitle: string,
    newId: string
  ) => void;
  checkDuplicateAbstract?: (refNumber: string) => boolean;
};

const AbstractItem = ({
  abstract,
  isNew,
  cancel,
  createCustomAbstract,
  deleteAbstract,
  updateAbstractInLocalStorage,
}: AbstractItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: abstract.id });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const prevTitleRef = useRef<string>(abstract.title);
  const prevAbstractNumberRef = useRef<string>(abstract.abstractNumber);

  const [title, setTitle] = useState<string>(abstract.title);
  const [abstractNumber, setAbstractNumber] = useState<string>(
    abstract.abstractNumber
  );

  const [isError, setIsError] = useState<boolean>(false);

  const handleUpdateTitle = (newTitle: string) => {
    if (prevTitleRef.current === newTitle) {
      return;
    }

    updateAbstractInLocalStorage(abstract, newTitle, abstractNumber);

    prevTitleRef.current = newTitle.trim();
    setTitle(newTitle.trim());
  };

  const handleCreateAbstract = () => {
    if (!title.trim()) return;

    cancel();
    createCustomAbstract(title.trim());
    setTitle(title.trim());
  };

  return (
    <div
      className={classes.container}
      style={style}
      ref={setNodeRef}
      {...attributes}
    >
      {isNew ? (
        <div className={`${classes.titleWrapper} ${classes.newTitle}`}>
          <input
            type="text"
            id="title"
            className={classes.titleInput}
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <div className={classes.options}>
            <div
              className={`${classes.icon} ${classes.dismissIcon}`}
              onClick={cancel}
            ></div>
            <div
              className={`${classes.icon} ${classes.applyIcon}`}
              onClick={handleCreateAbstract}
            ></div>
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.titleWrapper}>
            <div
              className={`${classes.icon} ${classes.dragIcon}`}
              {...listeners}
            ></div>

            <input
              type="text"
              id="title"
              className={classes.titleInput}
              value={title}
              onBlur={(e) => handleUpdateTitle(e.target.value)}
              onChange={(e) => setTitle(e.target.value)}
            />

            <div
              className={`${classes.icon} ${classes.trashIcon}`}
              onClick={() =>
                deleteAbstract({
                  id: abstract.id,
                  title: title,
                  abstractNumber: abstractNumber,
                })
              }
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AbstractItem;
