import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  generateFigureDescriptionPromise,
  refreshFiguresPromise,
} from 'app/office-document';
import {
  figureType,
  getLocalStorage,
  updateFigures,
} from 'engine/localStorageHelper';
import { StatusCodes, StatusManager } from 'engine/models';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Figures/Figures.scss';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import Progress from 'components/Progress/Progress';
import FigureItem from 'components/FigureItem/FigureItem';
import { UIStrings } from 'app/UIStrings';

const Figures = () => {
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [claims, setClaims] = useState<string[]>([]);
  const [figures, setFigures] = useState<figureType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<
    'refresh' | 'generate' | 'complete'
  >(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const claims = getLocalStorage().claims;
        setClaims(claims);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  useEffect(() => {
    const localStorage = getLocalStorage();
    const figures = localStorage.figures;
    const claims = localStorage.claims;

    setFigures(figures);
    setClaims(claims);
  }, []);

  const updateProgress = (type: 'refresh' | 'generate' | 'complete') => {
    const completeFigures = figures.filter((figure) => figure.complete);

    // TODO - implement progress calculation if needed
    if (type === 'refresh') {
    }

    if (type === 'generate') {
      return setStatus((prevStatus) => {
        return { ...prevStatus, figures: 100, references: 100 };
      });
    }

    if (type === 'complete') {
      return setStatus((prevStatus) => {
        if (completeFigures.length === 0) {
          return { ...prevStatus, figures: 0 };
        }

        if (completeFigures.length === figures.length) {
          return { ...prevStatus, figures: 50 };
        }

        return { ...prevStatus, figures: 25 };
      });
    }
  };

  const refresh = () => {
    refreshFiguresPromise(statusManager);
    setTypeOfAction('refresh');
  };

  const generateFigureDescription = () => {
    generateFigureDescriptionPromise(statusManager);
    setTypeOfAction('generate');
  };

  // TODO - implement abort method
  const abortHandler = () => {};

  const createCustomFigure = (title: string) => {
    const newFigures: figureType[] = [
      ...figures,
      {
        id: `${crypto.randomUUID()}`,
        title: title.trim(),
        description: 'Beschreibung',
        claimIds: [],
        complete: false,
      },
    ];

    updateFiguresInLocalStorage(newFigures);
    updateProgress('complete');
  };

  const updateFigure = (
    figureToUpdate: figureType,
    newTitle: string,
    newDescription: string,
    newClaimIds: number[]
  ) => {
    const isComplete =
      newTitle.trim() && newDescription.trim() && newClaimIds.length > 0;

    const indexOfFigure = figures.findIndex(
      (figure) => figure.id === figureToUpdate.id
    );
    const newFigures = figures;
    newFigures.splice(indexOfFigure, 1, {
      id: figureToUpdate.id,
      title: newTitle.trim(),
      description: newDescription.trim(),
      claimIds: newClaimIds,
      complete: isComplete,
    });

    updateFiguresInLocalStorage(newFigures);
    updateProgress('complete');
  };

  const deleteFigure = (figureToDelete: figureType) => {
    const newFigures: figureType[] = figures.filter(
      (figure) => figure.id !== figureToDelete.id
    );

    updateFiguresInLocalStorage(newFigures);
    updateProgress('complete');
  };

  const updateFiguresInLocalStorage = (newFigures: figureType[]) => {
    setFigures(newFigures);
    updateFigures(newFigures);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.figures.figures_title}
          description={UIStrings.figures.figures_description}
          color="black"
          hasProgress={true}
          progress={status.figures}
          refresh={refresh}
        />

        {figures.length > 0 &&
          figures.map((figure: figureType) => (
            <FigureItem
              key={figure.id}
              figure={figure}
              claims={claims}
              updateFigure={updateFigure}
              deleteFigure={deleteFigure}
            />
          ))}

        {isNew && (
          <FigureItem
            figure={{
              id: '',
              title: '',
              description: '',
              claimIds: [],
              complete: false,
            }}
            claims={claims}
            isNew={isNew}
            createCustomFigure={createCustomFigure}
            close={() => setIsNew(false)}
          />
        )}

        <Button
          title={UIStrings.figures.figures_add}
          color="transparent"
          size="big"
          icon="add"
          onClick={() => setIsNew(true)}
        />

        <div className={classes.generateFiguresButton}>
          <Button
            title={UIStrings.figures.figures_generate}
            color="blue"
            size="big"
            onClick={generateFigureDescription}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Figures;
