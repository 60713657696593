import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import {
  applyReferencesPromise,
  refreshReferencesPromise,
  initialRefresh,
} from 'app/office-document';
import { StatusCodes, StatusManager } from 'engine/models';
import {
  getLocalStorage,
  referenceType,
  suggestionType,
  updateReferences,
  updateSuggestions,
} from 'engine/localStorageHelper';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Reference/Reference.scss';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import ReferenceItem from 'components/ReferenceItem/ReferenceItem';
import { UIStrings } from 'app/UIStrings';

const Reference = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [references, setReferences] = useState<referenceType[]>([]);
  const [suggestions, setSuggestions] = useState<suggestionType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'refresh' | 'apply'>(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const suggestions = getLocalStorage().suggestions;
        setSuggestions(suggestions);
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  useEffect(() => {
    if (location.state) {
      const prevPath = location.state.prevPath;
      if (prevPath === 'consistency-claims') {
        refresh();
      }
    }
  }, []);

  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  const updateProgress = (type: 'refresh' | 'apply') => {
    if (type === 'refresh') {
      return setStatus((prevStatus) => {
        if (prevStatus.references === 100) {
          return prevStatus;
        }

        if (prevStatus.references === 50 && suggestions.length > 0) {
          return prevStatus;
        }

        return { ...prevStatus, references: prevStatus.references + 50 };
      });
    }

    if (type === 'apply') {
      return setStatus((prevStatus) => {
        if (prevStatus.references === 100) {
          return prevStatus;
        }

        if (references.length === 0) {
          return prevStatus;
        }

        return { ...prevStatus, references: prevStatus.references + 50 };
      });
    }
  };

  useEffect(() => {
    const localStorage = getLocalStorage();
    const references = localStorage.references;
    const suggestions = localStorage.suggestions;

    if (references.length > 0) {
      setReferences(references);
    }

    if (suggestions.length > 0) {
      setSuggestions(suggestions);
    }
  }, []);

  const refresh = () => {
    initialRefresh(statusManager);
    setTypeOfAction('refresh');
  };

  const addToDocument = () => {
    applyReferencesPromise(statusManager);
    setTypeOfAction('apply');
  };

  // TODO - implement abort method
  const abortHandler = () => {};

  const renumberReferences = () => {
    const newReferences: referenceType[] = [];
    for (let i = 1; i <= references.length; i++) {
      newReferences.push({
        ...references[i - 1],
        referenceNumber: i.toString(),
      });
    }

    updateReferencesInLocalStorage(newReferences);
  };

  const createCustomReference = (title: string) => {
    const newReferences: referenceType[] = [
      ...references,
      {
        id: `${crypto.randomUUID()}`,
        title: title.trim(),
        rootWord: '',
        searchWholePhrase: false,
        leftPhrase: '',
        referenceNumber: getNewReferenceNumber(),
      },
    ];

    updateReferencesInLocalStorage(newReferences);
  };

  const createCustomSuggestion = (title: string) => {
    if (!title.trim()) return;

    const newSuggestions: suggestionType[] = suggestions;
    const indexOfSuggestion = suggestions.findIndex(
      (item) => item.title === title
    );

    if (indexOfSuggestion >= 0) {
      newSuggestions.splice(indexOfSuggestion, 1, {
        title: title.trim(),
        enabled: true,
      });
    } else {
      newSuggestions.push({
        title: title.trim(),
        enabled: true,
      });
    }

    updateSuggestionsInLocalStorage(newSuggestions);
  };

  const addSuggestionReference = (suggestionToAdd: suggestionType) => {
    const newSuggestions: suggestionType[] = suggestions.filter(
      (suggestion) => suggestion.title !== suggestionToAdd.title
    );

    createCustomReference(suggestionToAdd.title);
    updateSuggestionsInLocalStorage(newSuggestions);
  };

  const deleteReference = (referenceToDelete: referenceType) => {
    const newReferences: referenceType[] = references.filter(
      (reference) => reference.id !== referenceToDelete.id
    );

    createCustomSuggestion(referenceToDelete.title);
    updateReferencesInLocalStorage(newReferences);
  };

  const updateReferenceInLocalStorage = (
    referenceToUpdate: referenceType,
    newTitle: string,
    newReferenceNumber: string
  ) => {
    const indexOfReference = references.findIndex(
      (reference) => reference.id === referenceToUpdate.id
    );
    const newReferences = references;
    newReferences.splice(indexOfReference, 1, {
      id: referenceToUpdate.id,
      title: newTitle.trim(),
      rootWord: '',
      searchWholePhrase: false,
      leftPhrase: '',
      referenceNumber: newReferenceNumber,
    });

    updateReferencesInLocalStorage(newReferences);
  };

  const updateReferencesInLocalStorage = (newReferences: referenceType[]) => {
    setReferences(newReferences);
    updateReferences(newReferences);
  };

  const updateSuggestionsInLocalStorage = (
    newSuggestions: suggestionType[]
  ) => {
    setSuggestions(newSuggestions);
    updateSuggestions(newSuggestions);
  };

  const checkDuplicateReference = (refNumber: string) => {
    const indexOfDuplicateReference = references.findIndex(
      (reference) => reference.referenceNumber === refNumber
    );

    return indexOfDuplicateReference > -1 && references.length > 1;
  };

  const getNewReferenceNumber = () => {
    if (references.length === 0) return '1';

    const numberPattern = /\d+/g;

    const formattedReferences = references.map((referenceItem) => {
      const onlyNumbers = referenceItem.referenceNumber.match(numberPattern);
      const formattedReferenceNumber = onlyNumbers ? onlyNumbers.join('') : '0';

      return {
        ...referenceItem,
        referenceNumber: formattedReferenceNumber,
      };
    });

    const maxReferenceNumber =
      Math.max(
        ...formattedReferences.map(
          (referenceItem: referenceType) => +referenceItem.referenceNumber
        )
      ) + 1;

    return maxReferenceNumber.toString();
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const oldIndex = references.findIndex(
      (reference) => reference.id === active.id
    );
    const newIndex = references.findIndex(
      (reference) => reference.id === over.id
    );
    const newReferences: referenceType[] = arrayMove(
      references,
      oldIndex,
      newIndex
    );

    setReferences(newReferences);
    updateReferences(newReferences);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.references.references_title}
          description={UIStrings.references.references_description}
          color="black"
          hasProgress={true}
          progress={status.references}
          refresh={refresh}
        />

        <div className={classes.main}>
          <div>
            <div className={classes.titleWrapper}>
              {UIStrings.references.references_title}
              <div onClick={renumberReferences} className={classes.renumber}>
                <div
                  className={`${classes.icon} ${classes.renumberIcon}`}
                ></div>
                {UIStrings.references.references_renumber}
              </div>
            </div>

            {references.length > 0 || isNew ? (
              <div className={classes.referenceList}>
                <DndContext onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={references}
                    strategy={verticalListSortingStrategy}
                  >
                    {references.map((reference: referenceType) => (
                      <ReferenceItem
                        key={`${reference.id}.${reference.title}.${reference.referenceNumber}`}
                        reference={reference}
                        deleteReference={deleteReference}
                        updateReferenceInLocalStorage={
                          updateReferenceInLocalStorage
                        }
                        checkDuplicateReference={checkDuplicateReference}
                      />
                    ))}

                    {isNew && (
                      <ReferenceItem
                        reference={{
                          id: '',
                          title: '',
                          rootWord: '',
                          searchWholePhrase: false,
                          leftPhrase: '',
                          referenceNumber: getNewReferenceNumber(),
                        }}
                        isNew={isNew}
                        cancel={() => setIsNew(false)}
                        createCustomReference={createCustomReference}
                      />
                    )}
                  </SortableContext>
                </DndContext>

                <Button
                  title={UIStrings.references.references_add}
                  color="reference"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            ) : (
              <div className={classes.emptyList}>
                <div>{UIStrings.references.references_empty}</div>
                <Button
                  title={UIStrings.references.references_add}
                  color="transparent"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            )}
          </div>

          <div>
            <div className={classes.titleWrapper}>
              {UIStrings.references.suggestions_title}
            </div>
            <div className={classes.suggestionList}>
              {suggestions.length > 0 &&
                suggestions.map(
                  (suggestion: suggestionType, index: number) =>
                    suggestion.enabled && (
                      <div
                        key={suggestion.title + index}
                        className={classes.suggestionItem}
                      >
                        <div>{suggestion.title}</div>
                        <div
                          className={`${classes.icon} ${classes.addIcon}`}
                          onClick={() => addSuggestionReference(suggestion)}
                        ></div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>

        <div className={classes.addToDocumentButton}>
          <Button
            title={UIStrings.references.add_to_document}
            color="blue"
            size="big"
            onClick={addToDocument}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Reference;
