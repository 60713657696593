import { useEffect, useState } from 'react';
import { Slider, Switch } from '@fluentui/react-components';

import {
  getLocalStorage,
  localStorageType,
  settingsType,
  updateSettings,
} from 'engine/localStorageHelper';
import * as classes from 'views/Settings/Settings.scss';
import TabHeader from 'components/TabHeader/TabHeader';
import { UIStrings } from 'app/UIStrings';

const Settings = () => {
  const [timer, setTimer] = useState(null);

  const [isInsertDefinition, setIsInsertDefinition] = useState<boolean>(null);
  const [maxPatentClaims, setMaxPatentClaims] = useState<number>(null);

  useEffect(() => {
    const localStorage: localStorageType = getLocalStorage();
    const settings: settingsType = localStorage.settings;

    setIsInsertDefinition(settings.isInsertDefinition);
    setMaxPatentClaims(settings.maxPatentClaims);
  }, []);

  const handleSwitchChange = () => {
    setIsInsertDefinition(!isInsertDefinition);
    updateSettingsInLocalStorage({
      isInsertDefinition: !isInsertDefinition,
      maxPatentClaims: maxPatentClaims,
    });
  };

  const handleSliderChange = (value: number) => {
    clearTimeout(timer);

    if (value < 1) {
      setMaxPatentClaims(1);
    } else if (value > 50) {
      setMaxPatentClaims(50);
    } else {
      setMaxPatentClaims(value);
    }

    const newTimer = setTimeout(() => {
      if (value < 1) {
        updateSettingsInLocalStorage({
          isInsertDefinition: isInsertDefinition,
          maxPatentClaims: 1,
        });

        return;
      }

      if (value > 50) {
        updateSettingsInLocalStorage({
          isInsertDefinition: isInsertDefinition,
          maxPatentClaims: 50,
        });

        return;
      }

      updateSettingsInLocalStorage({
        isInsertDefinition: isInsertDefinition,
        maxPatentClaims: value,
      });
    }, 500);

    setTimer(newTimer);
  };

  const updateSettingsInLocalStorage = (newSettings: settingsType) => {
    updateSettings(newSettings);
  };

  return (
    <div className={classes.container}>
      <TabHeader title={UIStrings.settings.settings_title} color="black" />

      <div>
        <div
          className={`${classes.settingItem} ${classes.insertDefinitionSetting}`}
        >
          <div className={classes.settingTitle}>
            <div>{UIStrings.settings.settings_description}</div>
            <Switch
              checked={isInsertDefinition}
              onChange={handleSwitchChange}
            />
          </div>
        </div>

        <div className={`${classes.settingItem} ${classes.maxClaimsSetting}`}>
          <div className={classes.settingTitle}>
            <div>{UIStrings.settings.settings_max_claims}</div>
            <input
              type="number"
              id="claims"
              className={classes.maxClaimsInput}
              value={maxPatentClaims}
              onChange={(e) => handleSliderChange(+e.target.value)}
            />
          </div>

          <Slider
            value={maxPatentClaims}
            min={1}
            max={50}
            onChange={(e) => handleSliderChange(+e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
