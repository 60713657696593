import { useEffect, useState } from 'react';
import { Link, useNavigate, useResolvedPath } from 'react-router-dom';

import {
  consistencyType,
  getLocalStorage,
  getTestByName,
  getTestErrors,
  setTestErrors,
  testErrorType,
} from 'engine/localStorageHelper';
import {
  rescanSingleTestErrorsPromise,
  correctSingleErrorPromise,
  rescanAllTestsErrorsPromise,
} from 'app/office-document';
import { StatusCodes, StatusManager } from 'engine/models';

import * as classes from 'views/ConsistencyClaims/ConsistencyClaims.scss';
import Button from 'components/Button/Button';
import TabHeader from 'components/TabHeader/TabHeader';
import ConsistencyItem from 'components/ConsistencyItem/ConsistencyItem';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import Progress from 'components/Progress/Progress';
import { UIStrings } from 'app/UIStrings';
import { getConsistencyByTestKey } from 'engine/consistency';

const ConsistencyClaims = () => {
  const navigate = useNavigate();

  const linkToReferenceTab = useResolvedPath(`/index.html/reference`);
  const linkToTemplateTab = useResolvedPath(`/index.html/template`);

  const [errors, setErrors] = useState<consistencyType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.Success) {
        const testErrors = getLocalStorage().consistency;
        setErrors(testErrors);
      }
    },
  };

  useEffect(() => {
    rescanAllClaimsTestsErrors();
  }, []);

  const rescanSingleClaimsTestErrors = (testName: string) => {
    rescanSingleTestErrorsPromise(statusManager, testName);
  };

  const rescanAllClaimsTestsErrors = () => {
    rescanAllTestsErrorsPromise(statusManager, true);
  };

  const acceptTestError = (testName: string, testErrorToFix: testErrorType) => {
    if (testErrorToFix.skip) return;

    const testErrors = getTestErrors(testName);
    const indexOfError = testErrors.findIndex(
      (error) => error.id === testErrorToFix.id
    );

    const newTestErrors = testErrors;
    newTestErrors.splice(indexOfError, 1);

    const consItem = getConsistencyByTestKey(testName);

    updateErrors(testName, newTestErrors);
    setTestErrors(testName, consItem.testDisplayName, newTestErrors);

    correctSingleErrorPromise(statusManager, testErrorToFix);
  };

  const skipTestError = (
    testName: string,
    testErrorToSkip: testErrorType,
    skipReason: string
  ) => {
    if (testErrorToSkip.skip) return;

    const testErrors = getTestErrors(testName);
    const indexOfError = testErrors.findIndex(
      (error) => error.id === testErrorToSkip.id
    );

    const newTestErrors = testErrors;
    newTestErrors.splice(indexOfError, 1, {
      ...testErrorToSkip,
      skip: true,
      skipReason: skipReason.trim(),
    });

    const consItem = getConsistencyByTestKey(testName);

    updateErrors(testName, newTestErrors);
    setTestErrors(testName, consItem.testDisplayName, newTestErrors);
  };

  const getErrorsCount = (testErrorsToGetErrorsCount: consistencyType[]) => {
    let errorsCount = 0;
    testErrorsToGetErrorsCount.forEach((test) => {
      const testErrorsWithoutSkipped = test.testErrors.filter(
        (error) => !error.skip
      );
      errorsCount += testErrorsWithoutSkipped.length;
    });

    return errorsCount;
  };

  const updateErrors = (testName: string, testErrors: testErrorType[]) => {
    const newErrors = errors.map((test) => {
      if (test.testName === testName) {
        return {
          ...test,
          testErrors: testErrors,
        };
      }
      return test;
    });

    setErrors(newErrors);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <Link to={linkToTemplateTab.pathname} replace className={classes.link}>
          <div className={classes.goBackButton}>
            <div className={`${classes.icon} ${classes.arrowLeftIcon}`}></div>
            {UIStrings.consistency_claims.back_button}
          </div>
        </Link>

        <TabHeader
          title={UIStrings.consistency_claims.consistency_title}
          description={
            getErrorsCount(errors) === 0
              ? UIStrings.consistency_claims.consistency_description_noerrors
              : UIStrings.consistency_claims.consistency_description_errors
          }
          color="white"
          errors={getErrorsCount(errors)}
          refresh={rescanAllClaimsTestsErrors}
        />

        <div className={classes.errorsContainer}>
          {errors.map((test: consistencyType) => (
            <ConsistencyItem
              key={test.testName}
              testName={test.testName}
              testTitle={test.testTitle}
              testErrors={test.testErrors}
              rescanTestErrors={rescanSingleClaimsTestErrors}
              acceptTestError={acceptTestError}
              skipTestError={skipTestError}
            />
          ))}
        </div>

        <div className={classes.continueButton}>
          <Link
            to={linkToReferenceTab.pathname}
            state={{ prevPath: 'consistency-claims' }}
            replace
            className={classes.link}
          >
            <Button
              title={UIStrings.consistency_claims.continue_button}
              color="white"
              size="big"
              disabled={getErrorsCount(errors) > 0}
            />
          </Link>
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
        />
      </div>
    </div>
  );
};

export default ConsistencyClaims;
