// extracted by mini-css-extract-plugin
var _1 = "eDNYafCcB6cTDyvzIA1J";
var _2 = "aQU570_hvyEDmnjCcqcg";
var _3 = "QRXU0q9lpD1RPgzgyhWA";
var _4 = "vOtiPiRd3K2OehewApyz";
var _5 = "LeeOciwrZSOmaAkPPRE4";
var _6 = "g9Z9tdEnbZ8Nh2fBttjL";
var _7 = "jEgfhVF1Vsc3wQYak0nw";
var _8 = "RDWwtbcvH2jb4zXys37S";
var _9 = "jN7iOoX5A4V7OfUQ5RYe";
var _a = "LhWU9oFcJhoCpjw_NWZw";
var _b = "Ea6BSWFqwZsYmGFMGwmw";
var _c = "A6ZYVCMe2I76w8adwAQ0";
export { _1 as "addIcon", _2 as "addToDocumentButton", _3 as "container", _4 as "emptyList", _5 as "icon", _6 as "main", _7 as "referenceList", _8 as "renumber", _9 as "renumberIcon", _a as "suggestionItem", _b as "suggestionList", _c as "titleWrapper" }
