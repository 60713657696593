import { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Divider } from '@fluentui/react-components';

import {
  fillAllClaimsDescriptionsPromise,
  fillAllZonesPromise,
  fillZonePromise,
} from 'app/office-document';
import { FillZoneType, StatusCodes, StatusManager } from 'engine/models';

import * as classes from 'views/TextGeneration/TextGeneration.scss';
import { RouterContextType } from 'views/App/App';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import { UIStrings } from 'app/UIStrings';

const TextGeneration = () => {
  const navigate = useNavigate();

  const operationRef = useRef(null);
  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'single' | 'all'>(null);

  const [taskInProgress, setTaskInProgress] = useState<number>(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);
      const taskNames = ['title', 'text', 'description', 'summary'];

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }

      if (code === StatusCodes.InProgress) {
        // set loader
        setTaskInProgress(statusManager.currentTaskId);
        setStatus((prevStatus) => {
          const taskName = taskNames[statusManager.currentTaskId];

          return {
            ...prevStatus,
            generation: {
              ...prevStatus.generation,
              actionStatus: {
                ...prevStatus.generation.actionStatus,
                [`${taskName}`]: false,
              },
            },
          };
        });
      }

      if (code === StatusCodes.Success) {
        // set complete
        setTaskInProgress(null);
        setStatus((prevStatus) => {
          const taskName = taskNames[statusManager.currentTaskId];

          return {
            ...prevStatus,
            generation: {
              ...prevStatus.generation,
              actionStatus: {
                ...prevStatus.generation.actionStatus,
                [`${taskName}`]: true,
              },
            },
          };
        });
      }

      if (code === StatusCodes.Aborted) {
        // set abort
        setTaskInProgress(null);
      }
    },
    currentTaskId: 0,
    bulkTaskCount: 1,
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  const generateAll = () => {
    operationRef.current = fillAllZonesPromise(statusManager);
    setTypeOfAction('all');
  };

  const generateTitle = () => {
    operationRef.current = fillZonePromise(statusManager, FillZoneType.Title);
    setTypeOfAction('single');
  };

  const generateText = () => {
    operationRef.current = fillZonePromise(
      statusManager,
      FillZoneType.SummaryTop
    );
    setTypeOfAction('single');
  };

  const generateDescription = () => {
    operationRef.current = fillAllClaimsDescriptionsPromise(statusManager);
    setTypeOfAction('single');
  };

  const generateSummary = () => {
    operationRef.current = fillZonePromise(
      statusManager,
      FillZoneType.SummaryBottom
    );
    setTypeOfAction('single');
  };

  const updateProgress = (type: 'single' | 'all') => {
    if (type === 'single') {
      return setStatus((prevStatus) =>
        prevStatus.generation.tabStatus === 100
          ? prevStatus
          : {
              ...prevStatus,
              generation: {
                ...prevStatus.generation,
                tabStatus: prevStatus.generation.tabStatus + 25,
              },
            }
      );
    }

    if (type === 'all') {
      return setStatus((prevStatus) => {
        return {
          ...prevStatus,
          generation: { ...prevStatus.generation, tabStatus: 100 },
        };
      });
    }
  };

  const abortHandler = () => {
    if (operationRef.current && operationRef.current.abort) {
      operationRef.current.abort();
    }
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title={UIStrings.text_generation.text_generation_title}
          description={UIStrings.text_generation.text_generation_description}
          color="black"
          hasProgress={true}
          progress={status.generation.tabStatus}
        />

        <div>
          <Button
            title={UIStrings.text_generation.generate_all}
            size="big"
            color={
              status.generation.actionStatus.title &&
              status.generation.actionStatus.text &&
              status.generation.actionStatus.description &&
              status.generation.actionStatus.summary
                ? 'green'
                : 'blue'
            }
            icon={
              status.generation.actionStatus.title &&
              status.generation.actionStatus.text &&
              status.generation.actionStatus.description &&
              status.generation.actionStatus.summary
                ? 'completeAll'
                : null
            }
            onClick={generateAll}
          />

          <Divider className={classes.divider}>
            {UIStrings.text_generation.text_generation_divider}
          </Divider>

          <div className={classes.buttons}>
            <Button
              title={UIStrings.text_generation.generate_title}
              size="big"
              color={
                taskInProgress === 0 || status.generation.actionStatus.title
                  ? 'generate'
                  : 'grey'
              }
              icon={
                status.generation.actionStatus.title
                  ? 'complete'
                  : taskInProgress === 0
                    ? 'load'
                    : null
              }
              onClick={generateTitle}
            />
            <Button
              title={UIStrings.text_generation.generate_intro}
              size="big"
              color={
                taskInProgress === 1 || status.generation.actionStatus.text
                  ? 'generate'
                  : 'grey'
              }
              icon={
                status.generation.actionStatus.text
                  ? 'complete'
                  : taskInProgress === 1
                    ? 'load'
                    : null
              }
              onClick={generateText}
            />
            <Button
              title={UIStrings.text_generation.generate_description}
              size="big"
              color={
                taskInProgress === 2 ||
                status.generation.actionStatus.description
                  ? 'generate'
                  : 'grey'
              }
              icon={
                status.generation.actionStatus.description
                  ? 'complete'
                  : taskInProgress === 2
                    ? 'load'
                    : null
              }
              onClick={generateDescription}
            />
            <Button
              title={UIStrings.text_generation.generate_summary}
              size="big"
              color={
                taskInProgress === 3 || status.generation.actionStatus.summary
                  ? 'generate'
                  : 'grey'
              }
              icon={
                status.generation.actionStatus.summary
                  ? 'complete'
                  : taskInProgress === 3
                    ? 'load'
                    : null
              }
              onClick={generateSummary}
            />
          </div>
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default TextGeneration;
