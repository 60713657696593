import { Spinner } from '@fluentui/react-components';

import * as classes from 'components/Button/Button.scss';

type ButtonProps = {
  title: string;
  color:
    | 'blue'
    | 'grey'
    | 'white'
    | 'green'
    | 'transparent'
    | 'reference'
    | 'generate';
  size: 'small' | 'big';
  disabled?: true | false;
  icon?: 'add' | 'refresh' | 'complete' | 'completeAll' | 'load';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button = ({
  title,
  color,
  size,
  disabled,
  icon,
  onClick,
}: ButtonProps) => {
  const buttonIconClassName = icon + 'Icon';

  return (
    <div
      style={{
        maxWidth: size === 'big' && '298px',
        width: size === 'big' && '100%',
      }}
    >
      <button
        className={`${classes.button} ${classes[color]} ${classes[size]}`}
        disabled={disabled}
        onClick={onClick}
      >
        {icon && (
          <>
            {icon === 'load' ? (
              <Spinner size="extra-tiny" appearance="primary" />
            ) : (
              <div
                className={`${classes.icon} ${classes[buttonIconClassName]}`}
              ></div>
            )}
          </>
        )}
        {title}
      </button>
    </div>
  );
};

export default Button;
