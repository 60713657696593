import { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import { applyAbstractsPromise } from 'app/office-document';
import { StatusCodes, StatusManager } from 'engine/models';
import {
  getLocalStorage,
  abstractType,
  updateAbstracts,
} from 'engine/localStorageHelper';

import { RouterContextType } from 'views/App/App';
import * as classes from 'views/Abstract/Abstract.scss';
import Progress from 'components/Progress/Progress';
import DisabledContainer from 'components/DisabledContainer/DisabledContainer';
import TabHeader from 'components/TabHeader/TabHeader';
import Button from 'components/Button/Button';
import AbstractItem from 'components/AbstractItem/AbstractItem';

const Abstract = () => {
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>(false);
  const [abstracts, setAbstracts] = useState<abstractType[]>([]);

  const [progress, setProgress] = useState<number>();
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusCode, setStatusCode] = useState<StatusCodes>();
  const [typeOfAction, setTypeOfAction] = useState<'refresh' | 'apply'>(null);

  const statusManager: StatusManager = {
    setProgress: (value) => setProgress(value),
    setStatusMessage: (message) => setStatusMessage(message),
    setStatusCode: (code) => {
      setStatusCode(code);

      if (code === StatusCodes.Unauthorized) {
        return navigate('/index.html', { replace: true });
      }
    },
  };

  const { status, setStatus }: RouterContextType = useOutletContext();

  useEffect(() => {
    if (statusCode === StatusCodes.Success) {
      updateProgress(typeOfAction);
    }
  }, [statusCode]);

  const updateProgress = (type: 'refresh' | 'apply') => {
    if (type === 'apply') {
      setStatus((prevStatus) => {
        if (abstracts.length === 0) {
          return { ...prevStatus, abstracts: 0 }; // No abstracts: set to 0
        } else {
          return { ...prevStatus, abstracts: 100 }; // Abstracts are present and being applied: set to 100
        }
      });
    } else if (type === 'refresh') {
      setStatus((prevStatus) => {
        if (abstracts.length > 0) {
          return { ...prevStatus, abstracts: 50 }; // Abstracts are present but not applied: set to 50
        } else {
          return { ...prevStatus, abstracts: 0 }; // No abstracts upon refresh
        }
      });
    }
  };

  useEffect(() => {
    const localStorage = getLocalStorage();
    const abstracts = localStorage.abstracts;

    setAbstracts(abstracts);
  }, []);

  const addToDocument = () => {
    console.log('add to document');
    applyAbstractsPromise(statusManager);
    setTypeOfAction('apply');
  };

  // TODO
  const abortHandler = () => {
    console.log('abort');
  };

  const createCustomAbstract = (title: string) => {
    console.log('create custom abstract', title);

    const newAbstracts: abstractType[] = [
      ...abstracts,
      {
        id: `${crypto.randomUUID()}`,
        title: title.trim(),
        abstractNumber: '0',
      },
    ];

    updateAbstractsInLocalStorage(newAbstracts);
    setTypeOfAction('refresh'); // Trigger refresh to update status
  };

  const deleteAbstract = (abstractToDelete: abstractType) => {
    console.log('delete abstract', abstractToDelete);

    const newAbstracts: abstractType[] = abstracts.filter(
      (abstract) => abstract.id !== abstractToDelete.id
    );

    updateAbstractsInLocalStorage(newAbstracts);
    setTypeOfAction('refresh'); // Trigger refresh to update status
  };

  const updateAbstractInLocalStorage = (
    abstractToUpdate: abstractType,
    newTitle: string,
    newAbstractNumber: string
  ) => {
    console.log(
      'update abstract in local storage',
      newTitle,
      newAbstractNumber
    );

    const indexOfAbstract = abstracts.findIndex(
      (abstract) => abstract.id === abstractToUpdate.id
    );
    const newAbstracts = abstracts;
    newAbstracts.splice(indexOfAbstract, 1, {
      id: abstractToUpdate.id,
      title: newTitle.trim(),
      abstractNumber: newAbstractNumber,
    });

    updateAbstractsInLocalStorage(newAbstracts);
  };

  const updateAbstractsInLocalStorage = (newAbstracts: abstractType[]) => {
    setAbstracts(newAbstracts);
    updateAbstracts(newAbstracts);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const oldIndex = abstracts.findIndex(
      (abstract) => abstract.id === active.id
    );
    const newIndex = abstracts.findIndex((abstract) => abstract.id === over.id);
    const newAbstracts: abstractType[] = arrayMove(
      abstracts,
      oldIndex,
      newIndex
    );

    setAbstracts(newAbstracts);
    updateAbstracts(newAbstracts);
  };

  return (
    <div>
      {(statusCode === 0 || statusCode === 1) && <DisabledContainer />}

      <div className={classes.container}>
        <TabHeader
          title="Patentnummer-Liste"
          description="Verwalten sie hier ihre Liste an verwandten Patenten. Bitte geben sie die Patentnummer ohne KindCode ein, also .B. 'US2019204427' statt 'US2019204427A1'."
          color="black"
          hasProgress={true}
          progress={status.abstracts}
        />

        <div className={classes.main}>
          <div>
            <div className={classes.titleWrapper}>Patentnummer-Liste</div>

            {abstracts.length > 0 || isNew ? (
              <div className={classes.abstractList}>
                <DndContext onDragEnd={handleDragEnd}>
                  <SortableContext
                    items={abstracts}
                    strategy={verticalListSortingStrategy}
                  >
                    {abstracts.map((abstract: abstractType) => (
                      <AbstractItem
                        key={`${abstract.id}.${abstract.title}.${abstract.abstractNumber}`}
                        abstract={abstract}
                        deleteAbstract={deleteAbstract}
                        updateAbstractInLocalStorage={
                          updateAbstractInLocalStorage
                        }
                      />
                    ))}

                    {isNew && (
                      <AbstractItem
                        abstract={{
                          id: '',
                          title: '',
                          abstractNumber: '0',
                        }}
                        isNew={isNew}
                        cancel={() => setIsNew(false)}
                        createCustomAbstract={createCustomAbstract}
                      />
                    )}
                  </SortableContext>
                </DndContext>

                <Button
                  title="Abstrakte hinzufügen"
                  color="reference"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            ) : (
              <div className={classes.emptyList}>
                <div>
                  Sie haben noch keine Patente angegeben. Fügen sie die Nummern
                  verwandter Patente ein, um fortzufahren.
                </div>
                <Button
                  title="Patentnummer hinzufügen"
                  color="transparent"
                  size="big"
                  icon="add"
                  onClick={() => setIsNew(true)}
                />
              </div>
            )}
          </div>
        </div>

        <div className={classes.addToDocumentButton}>
          <Button
            title="Zum Dokument hinzufügen"
            color="blue"
            size="big"
            onClick={addToDocument}
          />
        </div>

        <Progress
          progress={progress}
          message={statusMessage}
          code={statusCode}
          canAbort={true}
          abortOperation={abortHandler}
        />
      </div>
    </div>
  );
};

export default Abstract;
