import * as classes from 'views/FinalDocument/FinalDocument.scss';
import { UIStrings } from 'app/UIStrings';
import Button from 'components/Button/Button';
import { tempStorage } from 'app/index';

const FinalDocument = () => {
  return (
    <div className={classes.container}>
      <div className={classes.finalImage}>
        <img src="../../../../assets/final.svg" alt="Final Document" />
      </div>
      <div className={classes.description}>
        {UIStrings.final.final_document}
      </div>

      <a
        className={classes.downloadButton}
        href={`${tempStorage.backend}/api/report/${tempStorage.reportId}`}
        download={tempStorage.reportId}
      >
        <Button title={UIStrings.final.download_pdf} color="white" size="big" />
      </a>
    </div>
  );
};

export default FinalDocument;
