import {
  fetchLogin,
  fetchSetToken,
  isAccessTokenValid,
} from 'engine/apiHelpers';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';

import { UIStrings } from 'app/UIStrings';

export type AuthErrors = {
  email?: string;
  password?: string;
  global?: string;
};

interface AuthProvider {
  isAuthenticated: boolean;
  email: null | string;
  password: null | string;
  signIn(email: string, password: string): Promise<void>;
}

const AuthProvider: AuthProvider = {
  isAuthenticated: false,
  email: null,
  password: null,
  async signIn(email: string, password: string) {
    await fetchLogin(email, password).then(async (response) => {
      if (response.accessToken) {
        this.isAuthenticated = true;
        await fetchSetToken(response.expiresIn);
      } else {
        this.isAuthenticated = false;
      }
    });
  },
};

async function loginAction({ request }: LoaderFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get('email') as string | null;
  const password = formData.get('password') as string | null;

  // Validate our form inputs and return validation errors via useActionData()
  if (!email || !password) {
    const errors: AuthErrors = {
      email: !email ? UIStrings.auth.invalid_email : undefined,
      password: !password ? UIStrings.auth.invalid_password : undefined,
    };

    return errors;
  }

  // Sign in and redirect to the proper destination if successful.
  try {
    await AuthProvider.signIn(email, password);

    if (AuthProvider.isAuthenticated) {
      return redirect('/index.html/template');
    } else {
      const errors: AuthErrors = {
        global: UIStrings.auth.invalid_credentials,
      };

      return errors;
    }
  } catch (error) {
    const errors: AuthErrors = {
      global: UIStrings.auth.invalid_login,
    };

    return errors;
  }
}

export async function checkAuth() {
  const isAuthenticated = await isAccessTokenValid();

  isAuthenticated
    ? (AuthProvider.isAuthenticated = true)
    : (AuthProvider.isAuthenticated = false);

  return isAuthenticated;
}

async function loginLoader() {
  // If the user was logged in, we redirect them to `/reference`.
  const isAuthenticated = await checkAuth();

  if (isAuthenticated) {
    return redirect('/index.html/template');
  }

  return null;
}

async function protectedLoader() {
  // If the user is not logged in and tries to access `/protected`, we redirect them to `/login`.
  const isAuthenticated = await checkAuth();

  if (!isAuthenticated) {
    return redirect('/index.html');
  }

  return null;
}

export { AuthProvider, loginAction, loginLoader, protectedLoader };
