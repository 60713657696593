import { useLocation } from 'react-router-dom';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

import * as classes from 'components/TabHeader/TabHeader.scss';

type TabHeaderProps = {
  title: string;
  color: string;
  description?: string;
  errors?: number;
  hasProgress?: boolean;
  progress?: number;
  refresh?: () => void;
};

const TabHeader = ({
  title,
  description,
  color,
  errors,
  hasProgress = false,
  progress,
  refresh,
}: TabHeaderProps) => {
  const location = useLocation();

  return (
    <div className={classes.container}>
      <div className={`${classes.title} ${classes[color + 'Title']}`}>
        <div>
          {title}

          {refresh && (
            <div
              className={`${classes.icon} ${classes.refreshIcon}`}
              style={{
                background:
                  location.pathname === '/index.html/consistency-claims'
                    ? '#FFF'
                    : '#1267B4',
              }}
              onClick={refresh}
            ></div>
          )}
        </div>

        {errors > 0 && <div className={classes.error}>({errors})</div>}

        {hasProgress && (
          <div style={{ width: 32, height: 32 }}>
            <CircularProgressbarWithChildren
              value={progress}
              minValue={0}
              maxValue={100}
              background={true}
              backgroundPadding={2}
              strokeWidth={10}
              styles={buildStyles({
                pathColor: '#1267B4',
                trailColor: '#EDF2FB',
                backgroundColor: 'transparent',
                strokeLinecap: 'round',
              })}
            >
              <div className={`${classes.icon} ${classes.applyIcon}`}></div>
            </CircularProgressbarWithChildren>
          </div>
        )}
      </div>

      {description && (
        <div
          className={`${classes.description} ${classes[color + 'Description']}`}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default TabHeader;
