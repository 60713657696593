import { UIStrings } from 'app/UIStrings';
import {
  findAllIndices,
  reloadClaimsToLocalStorage,
  setTotalProgressInBulk,
} from './addInMethods';
import {
  DISTANCES_FOUND_PERCENT_THRESHOLD,
  fetchDistances,
  fetchParallelTestClaims,
  fetchSearchStemmed,
  fetchTestSingleClaim,
} from './apiHelpers';
import { getConsistencyByTestKey, getContext } from './consistency';
import {
  emptyOrWhiteSpace,
  getBookmarkErrorIdFromParId,
  getGptErrorFromText,
  getMultiBlocksParagraphs,
  getMultiBlocksParagraphsWithProgress,
  getRandomHexId,
  insertBookmarkErrorIntoParId,
} from './documentProxy';
import {
  getLocalStorage,
  getTestErrors,
  testErrorType,
  setTestErrors,
  getTestErrorById,
  settingsType,
  localStorageType,
} from './localStorageHelper';
import {
  ConsistencyInstructionItem,
  PromiseStatus,
  StatusCodes,
  StatusManager,
} from './models';

const checkAlgoClaimsAmountConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const claimsMap = await reloadClaimsToLocalStorage();
  const foundErrors: testErrorType[] = [];

  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  const minClaimsNumber = 10;
  const actualClaimsNumber = claimsMap.claims.length;

  if (actualClaimsNumber < 10) {
    const errorId = getRandomHexId();
    const actualClaimsNumberStr = actualClaimsNumber.toString();
    const error: testErrorType = {
      id: errorId,
      context: `Used ${actualClaimsNumberStr} claims, it is less than ${minClaimsNumber}`,
      contextIdx: 0,
      substring: `${actualClaimsNumberStr}`,
      skip: false,
      skipReason: '',
      errStart: 5,
      errEnd: 5 + actualClaimsNumberStr.length - 1,
      corrStart: -1,
      corrEnd: -1,
    };
    foundErrors.push(error);
  }

  const settings: settingsType = localStorage.settings;
  if (settings) {
    if (actualClaimsNumber > settings.maxPatentClaims) {
      const errorId = getRandomHexId();
      const error: testErrorType = {
        id: errorId,
        context: `Used ${actualClaimsNumber} claims, it is more than ${settings.maxPatentClaims}`,
        contextIdx: 0,
        substring: `${actualClaimsNumber}`,
        skip: false,
        skipReason: '',
        errStart: -1,
        errEnd: -1,
        corrStart: -1,
        corrEnd: -1,
      };
      foundErrors.push(error);
    }
  }

  setTestErrors(consItem.testKey, consItem.testDisplayName, foundErrors);

  setTotalProgressInBulk(promiseStatus, 100);
  statusManager.setProgress(promiseStatus.progress);
  if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
    statusManager.setStatusCode(StatusCodes.Success);
  }
  statusManager.setStatusMessage(completedText);

  resolve({ completed: true });
};

const checkAlgoClaimsNumberingConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const claimsMap = await reloadClaimsToLocalStorage();
  const foundErrors: testErrorType[] = [];

  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  for (let i = 0; i < claimsMap.claims.length; i++) {
    let keyInt = claimsMap.idx[i];
    if (Number.isNaN(keyInt)) {
      keyInt = 0;
    }
    if (keyInt !== i + 1) {
      const iStr = `${i + 1}`;
      const errStr = `${keyInt}`;
      const errContext = `Expected number: ${iStr}, but found: ${errStr}.`;
      const errStart = errContext.lastIndexOf(':') + 1;
      const errEnd = errStart + errStr.length;
      const errorId = getRandomHexId();
      const error: testErrorType = {
        id: errorId,
        context: errContext,
        contextIdx: 0,
        substring: `${claimsMap.idx[i]}`,
        skip: false,
        skipReason: '',
        errStart: errStart,
        errEnd: errEnd,
        corrStart: -1,
        corrEnd: -1,
      };
      foundErrors.push(error);
    }
  }

  setTestErrors(consItem.testKey, consItem.testDisplayName, foundErrors);

  setTotalProgressInBulk(promiseStatus, 100);
  statusManager.setProgress(promiseStatus.progress);
  if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
    statusManager.setStatusCode(StatusCodes.Success);
  }
  statusManager.setStatusMessage(completedText);

  resolve({ completed: true });
};

const checkAlgoClaimsDescriptionsConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  const localStorage: localStorageType = getLocalStorage();

  const foundErrors: testErrorType[] = [];

  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  const commonParagraphs = await getMultiBlocksParagraphs(['common']);
  const commonParagraphStrings: string[] = [];
  for (let i = 0; i < commonParagraphs.length; i++) {
    const text = commonParagraphs[i].text;
    if (text) {
      commonParagraphStrings.push(text);
    }
  }

  for (let i = 0; i < localStorage.claimDescriptions.length; i++) {
    let foundDescription = false;
    const distancesResponse = await fetchDistances(
      localStorage.claimDescriptions[i],
      commonParagraphStrings
    );
    if (!distancesResponse.error) {
      const max = Math.max(...distancesResponse.percentage);
      if (max > DISTANCES_FOUND_PERCENT_THRESHOLD) {
        foundDescription = true;
      }
    }

    setTotalProgressInBulk(
      promiseStatus,
      Math.round((i / localStorage.claimDescriptions.length) * 90 + 10)
    );
    statusManager.setProgress(promiseStatus.progress);

    if (!foundDescription) {
      const iStr = `${i + 1}`;
      const errContext = `Description of claim: ${iStr} not found.`;
      const errStart = errContext.lastIndexOf(':') + 1;
      const errEnd = errStart + iStr.length;
      const errorId = getRandomHexId();
      const error: testErrorType = {
        id: errorId,
        context: errContext,
        contextIdx: 0,
        substring: iStr,
        skip: false,
        skipReason: '',
        errStart: errStart,
        errEnd: errEnd,
        corrStart: -1,
        corrEnd: -1,
      };
      foundErrors.push(error);
    }
  }

  setTestErrors(consItem.testKey, consItem.testDisplayName, foundErrors);

  setTotalProgressInBulk(promiseStatus, 100);
  statusManager.setProgress(promiseStatus.progress);
  if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
    statusManager.setStatusCode(StatusCodes.Success);
  }
  statusManager.setStatusMessage(completedText);

  resolve({ completed: true });
};

const checkAlgoAllSubjectivesConsistency = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  const foundErrors: testErrorType[] = [];

  let alertWords = [];
  if (consItem.messages.length > 0 && consItem.messages[0].text) {
    alertWords = consItem.messages[0].text
      .split(',')
      .map((item) => item.trim());
  }

  const allParagraphs = await getMultiBlocksParagraphsWithProgress(
    [
      'intro',
      'common',
      'definitions',
      'figures',
      'references',
      'claims',
      'summary',
    ],
    statusManager,
    promiseStatus,
    10,
    80
  );

  setTotalProgressInBulk(promiseStatus, 80);
  statusManager.setProgress(promiseStatus.progress);

  const paragraphTexts: string[] = [];

  if (!promiseStatus.aborted) {
    // Check inline references.
    // Iterate through each paragraph
    for (let i = 0; i < allParagraphs.length; i++) {
      const paragraph = allParagraphs[i];

      // Store original text
      paragraphTexts.push(paragraph.text);
    }

    const resp = await fetchSearchStemmed(paragraphTexts, alertWords, []);

    if (resp.error) {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(
        UIStrings.statusbar.checking_references_failed
      );
    } else {
      setTotalProgressInBulk(promiseStatus, 90);
      statusManager.setProgress(promiseStatus.progress);

      for (let p = 0; p < resp.paragraphs.length; p++) {
        const respPar = resp.paragraphs[p];
        const paragraph = allParagraphs[respPar.parIndex];
        let parText = paragraphTexts[respPar.parIndex];

        for (let s = 0; s < respPar.searchResults.length; s++) {
          const startIndex = respPar.searchResults[s].startIndex;
          const endIndex = respPar.searchResults[s].endIndex;

          console.log('startIndex:' + startIndex);
          console.log('endIndex:' + endIndex);

          const errContext = getContext(parText, startIndex, endIndex);
          const substring = parText.substring(startIndex, endIndex);

          const contextIdx = parText.indexOf(errContext);

          const startIndexInContext = startIndex - contextIdx;
          const endIndexInContext = endIndex - contextIdx;

          // Check if error already present.
          let errorId = await getBookmarkErrorIdFromParId(
            paragraph.uniqueLocalId,
            startIndex,
            endIndex
          );
          if (errorId) {
            console.log('error found:' + errorId);
          } else {
            errorId = await insertBookmarkErrorIntoParId(
              paragraph.uniqueLocalId,
              false,
              startIndex,
              endIndex
            );
            console.log('ErrorId generated:' + errorId);
          }
          console.log('Final ErrorId:' + errorId);
          const oldError = getTestErrorById(consItem.testKey, errorId);
          if (oldError) {
            console.log('Old ErrorId found in local storage:' + errorId);
            const testError: testErrorType = {
              id: oldError.id,
              skip: oldError.skip,
              skipReason: oldError.skipReason,
              context: oldError.context,
              contextIdx: oldError.contextIdx,
              substring: oldError.substring,
              errStart: oldError.errStart,
              errEnd: oldError.errEnd,
              corrStart: oldError.corrStart,
              corrEnd: oldError.corrEnd,
            };
            foundErrors.push(testError);
          } else {
            const testError: testErrorType = {
              skip: false,
              skipReason: '',
              id: errorId,
              context: errContext,
              contextIdx: contextIdx,
              substring: substring,
              errStart: startIndexInContext,
              errEnd: endIndexInContext,
              corrStart: -1,
              corrEnd: -1,
            };

            foundErrors.push(testError);
          }
        }

        setTotalProgressInBulk(
          promiseStatus,
          90 + p * (10 / resp.paragraphs.length)
        );
        statusManager.setProgress(promiseStatus.progress);
      }
    }

    setTestErrors(consItem.testKey, consItem.testDisplayName, foundErrors);

    setTotalProgressInBulk(promiseStatus, 100);
    statusManager.setProgress(promiseStatus.progress);
    if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
      statusManager.setStatusCode(StatusCodes.Success);
    }
    statusManager.setStatusMessage(completedText);

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const checkGptClaimsConsistency = async (
  statusManager: StatusManager,
  testName: string,
  promiseStatus: PromiseStatus,
  resolve
) => {
  await reloadClaimsToLocalStorage();
  const testErrors = getTestErrors(testName);
  const foundErrors: testErrorType[] = [];

  const consItem = getConsistencyByTestKey(testName);
  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  let claimsParagraphs = await getMultiBlocksParagraphs(['claims']);

  setTotalProgressInBulk(promiseStatus, 20);
  statusManager.setProgress(promiseStatus.progress);

  if (!promiseStatus.aborted) {
    const len = claimsParagraphs.length;

    for (let i = 0; i < len; i++) {
      if (promiseStatus.aborted) {
        break;
      }

      const paragraph = claimsParagraphs[i];
      let parText = paragraph.text;
      setTotalProgressInBulk(promiseStatus, 20 + Math.round((i / len) * 80));
      statusManager.setProgress(promiseStatus.progress);

      if (emptyOrWhiteSpace(parText)) {
        continue;
      }

      const resp = await fetchTestSingleClaim(parText, testName);
      if (resp.error) {
        statusManager.setStatusCode(StatusCodes.Fail);
        statusManager.setStatusMessage(
          UIStrings.statusbar.checking_claims_error
        );
      } else {
        if (resp.data) {
          console.log('ParText:' + parText);
          console.log('resp.data:' + resp.data);
          const matchesTags = getGptErrorFromText(resp.data, 0, false);
          console.log('matches tags:');
          console.log(matchesTags);
          const matches = getGptErrorFromText(resp.data, 0, true);
          console.log('matches:');
          console.log(matches);

          if (matches.length > 0) {
            for (let j = 0; j < matches.length; j++) {
              const startOfGptTag = matches[j].index;
              const endOfGptTag = startOfGptTag + matches[j][1].length - 1;

              const errContext = getContext(
                parText,
                startOfGptTag,
                endOfGptTag
              );
              const substring = parText.substring(
                startOfGptTag,
                endOfGptTag + 1
              ); // substring() needs the next char.
              const contextIdx = parText.indexOf(errContext);
              console.log('Error substring:"' + substring + '"');

              // Check if error already present.
              let errorFound = false;
              let errorId = await getBookmarkErrorIdFromParId(
                paragraph.uniqueLocalId,
                startOfGptTag,
                endOfGptTag
              );
              if (errorId) {
                console.log('error found:' + errorId);
                errorFound = true;
              } else {
                errorId = await insertBookmarkErrorIntoParId(
                  paragraph.uniqueLocalId,
                  false,
                  startOfGptTag,
                  endOfGptTag
                );

                console.log(errorId);
              }

              const oldError = getTestErrorById(testName, errorId);
              if (oldError) {
                const testError: testErrorType = {
                  id: oldError.id,
                  skip: oldError.skip,
                  skipReason: oldError.skipReason,
                  context: oldError.context,
                  contextIdx: oldError.contextIdx,
                  substring: oldError.substring,
                  errStart: oldError.errStart,
                  errEnd: oldError.errEnd,
                  corrStart: oldError.corrStart,
                  corrEnd: oldError.corrEnd,
                };
                foundErrors.push(testError);
              } else {
                if (!errorFound) {
                  const testError: testErrorType = {
                    skip: false,
                    skipReason: '',
                    id: errorId,
                    context: errContext,
                    contextIdx: contextIdx,
                    substring: substring,
                    errStart: startOfGptTag - contextIdx,
                    errEnd: endOfGptTag - contextIdx,
                    corrStart: -1,
                    corrEnd: -1,
                  };
                  foundErrors.push(testError);
                }
              }
            }
          }
        }
      }
    }

    setTestErrors(testName, consItem.testDisplayName, foundErrors);

    setTotalProgressInBulk(promiseStatus, 100);
    statusManager.setProgress(promiseStatus.progress);
    if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
      statusManager.setStatusCode(StatusCodes.Success);
    }
    statusManager.setStatusMessage(completedText);

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

const checkGptClaimsConsistencyParallel = async (
  statusManager: StatusManager,
  consItem: ConsistencyInstructionItem,
  promiseStatus: PromiseStatus,
  resolve
) => {
  await reloadClaimsToLocalStorage();
  const testErrors = getTestErrors(consItem.testKey);
  const foundErrors: testErrorType[] = [];

  let checkingText =
    UIStrings.statusbar.checking_claims_progress +
    ' ' +
    consItem.testDisplayName;
  let completedText =
    UIStrings.statusbar.checking_claims_completed +
    ' ' +
    consItem.testDisplayName;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(checkingText);

  setTotalProgressInBulk(promiseStatus, 10);
  statusManager.setProgress(promiseStatus.progress);

  let claimsParagraphs = await getMultiBlocksParagraphs(['claims']);

  setTotalProgressInBulk(promiseStatus, 20);
  statusManager.setProgress(promiseStatus.progress);

  if (!promiseStatus.aborted) {
    const len = claimsParagraphs.length;
    const parTexts: string[] = [];

    for (let i = 0; i < len; i++) {
      const paragraph = claimsParagraphs[i];
      parTexts.push(paragraph.text);
    }

    const resp = await fetchParallelTestClaims(parTexts, consItem.testKey);
    if (resp.error) {
      statusManager.setStatusCode(StatusCodes.Fail);
      statusManager.setStatusMessage(UIStrings.statusbar.checking_claims_error);
    } else {
      if (resp.items) {
        for (let i = 0; i < resp.items.length; i++) {
          const parText = parTexts[i];

          if (!resp.items[i]) continue;

          const respItem = resp.items[i];
          const paragraph = claimsParagraphs[i];

          console.log('ParText:' + parText);
          console.log('respItem:' + respItem);
          const matchesTags = getGptErrorFromText(respItem, 0, false);
          console.log('matches tags:');
          console.log(matchesTags);
          const matches = getGptErrorFromText(respItem, 0, true);
          console.log('matches:');
          console.log(matches);

          if (matches.length > 0) {
            for (let j = 0; j < matches.length; j++) {
              const startOfGptTag = matches[j].index;
              const endOfGptTag = startOfGptTag + matches[j][1].length - 1;

              const errContext = getContext(
                parText,
                startOfGptTag,
                endOfGptTag
              );
              const substring = parText.substring(
                startOfGptTag,
                endOfGptTag + 1
              ); // substring() needs the next char.
              const contextIdx = parText.indexOf(errContext);
              console.log('Error substring:"' + substring + '"');

              // Check if error already present.
              let errorFound = false;
              let errorId = await getBookmarkErrorIdFromParId(
                paragraph.uniqueLocalId,
                startOfGptTag,
                endOfGptTag
              );
              if (errorId) {
                console.log('error found:' + errorId);
                errorFound = true;
              } else {
                errorId = await insertBookmarkErrorIntoParId(
                  paragraph.uniqueLocalId,
                  false,
                  startOfGptTag,
                  endOfGptTag
                );

                console.log(errorId);
              }

              const oldError = getTestErrorById(consItem.testKey, errorId);
              if (oldError) {
                const testError: testErrorType = {
                  id: oldError.id,
                  skip: oldError.skip,
                  skipReason: oldError.skipReason,
                  context: oldError.context,
                  contextIdx: oldError.contextIdx,
                  substring: oldError.substring,
                  errStart: oldError.errStart,
                  errEnd: oldError.errEnd,
                  corrStart: oldError.corrStart,
                  corrEnd: oldError.corrEnd,
                };
                foundErrors.push(testError);
              } else {
                if (!errorFound) {
                  const testError: testErrorType = {
                    skip: false,
                    skipReason: '',
                    id: errorId,
                    context: errContext,
                    contextIdx: contextIdx,
                    substring: substring,
                    errStart: startOfGptTag - contextIdx,
                    errEnd: endOfGptTag - contextIdx,
                    corrStart: -1,
                    corrEnd: -1,
                  };
                  foundErrors.push(testError);
                }
              }
            }
          }
        }
      }
    }

    setTestErrors(consItem.testKey, consItem.testDisplayName, foundErrors);

    setTotalProgressInBulk(promiseStatus, 100);
    statusManager.setProgress(promiseStatus.progress);
    if (promiseStatus.currentTaskId == promiseStatus.bulkTaskCount - 1) {
      statusManager.setStatusCode(StatusCodes.Success);
    }
    statusManager.setStatusMessage(completedText);

    resolve({ completed: true });
  }

  if (promiseStatus.aborted) {
    resolve({ aborted: true });
  }
};

export {
  checkAlgoClaimsAmountConsistency,
  checkAlgoClaimsNumberingConsistency,
  checkAlgoClaimsDescriptionsConsistency,
  checkAlgoAllSubjectivesConsistency,
  checkGptClaimsConsistency,
  checkGptClaimsConsistencyParallel,
};
