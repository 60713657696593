import { Link, useResolvedPath } from 'react-router-dom';

import * as classes from 'views/PatentFormula/PatentFormula.scss';
import Button from 'components/Button/Button';
import { UIStrings } from 'app/UIStrings';

const PatentFormula = () => {
  const linkToConsistencyClaimsTab = useResolvedPath(
    `/index.html/consistency-claims`
  );
  const linkToTemplateTab = useResolvedPath(`/index.html/template`);

  return (
    <div className={classes.container}>
      <Link to={linkToTemplateTab.pathname} replace className={classes.link}>
        <div className={classes.goBackButton}>
          <div className={`${classes.icon} ${classes.arrowLeftIcon}`}></div>
          {UIStrings.patent_formula.back_button}
        </div>
      </Link>

      <div className={classes.logo}>
        <img
          src="../../../../assets/patent-formula.svg"
          alt="Patent Assistant Logo"
        />
      </div>

      <div className={classes.description}>
        {UIStrings.patent_formula.patent_formula_description}
      </div>

      <Link
        to={linkToConsistencyClaimsTab.pathname}
        state={{ prevPath: 'patent-formula' }}
        replace
        className={classes.link}
      >
        <Button
          title={UIStrings.patent_formula.check_claims}
          color="white"
          size="big"
        />
      </Link>
    </div>
  );
};

export default PatentFormula;
