// extracted by mini-css-extract-plugin
var _1 = "qsIHeC6S9JOLorvuxR8V";
var _2 = "ptbMrGivrWk8waRHQTfQ";
var _3 = "_ZkiP6P5bXnE9JBGqMAb";
var _4 = "IxerbV0axPYTT4v_ysgP";
var _5 = "CNcyQlZAr3Do4nKGkBsr";
var _6 = "aoPBF9J2bSfqfe2ffMGg";
var _7 = "ZIwe9AbNFf5L7xgsvYMR";
var _8 = "Gcy20LInZktG996ZqbW5";
var _9 = "_9KmVsOmfvUS4zMEhmup";
export { _1 as "consistencyIcon", _2 as "definitionIcon", _3 as "figuresIcon", _4 as "generationIcon", _5 as "icon", _6 as "link", _7 as "referenceIcon", _8 as "settingsIcon", _9 as "statusBadge" }
