import * as classes from 'components/InputError/InputError.scss';

type ErrorInputProps = {
  errorMessage: string;
};

const ErrorInput = ({ errorMessage }: ErrorInputProps) => {
  return (
    <div className={classes.container}>
      <div className={`${classes.icon} ${classes.errorCircleIcon}`}></div>
      <div className={classes.errorMessage}>{errorMessage}</div>
    </div>
  );
};

export default ErrorInput;
