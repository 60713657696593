import { useEffect, useRef, useState } from 'react';

import { definitionType } from 'engine/localStorageHelper';
import * as classes from 'components/DefinitionItem/DefinitionItem.scss';
import InputError from 'components/InputError/InputError';
import { UIStrings } from 'app/UIStrings';

type DefinitionItemProps = {
  definition: definitionType;
  isNew?: boolean;
  close?: () => void;
  onItemCheck?: (definition: definitionType) => void;
  onItemUnCheck?: (definition: definitionType) => void;
  updateDefinitionInDB?: (definition: definitionType) => void;
  createCustomDefinition?: (title: string) => void;
  updateDefinition?: (
    definition: definitionType,
    newTitle: string,
    newDescription: string
  ) => void;
  checkDuplicateDefinition: (title: string) => boolean;
  updateCheckedDefinitions?: (title: string, newDescription: string) => void;
};

const DefinitionItem = ({
  definition,
  isNew = false,
  close,
  onItemCheck,
  onItemUnCheck,
  createCustomDefinition,
  updateDefinitionInDB,
  updateDefinition,
  checkDuplicateDefinition,
  updateCheckedDefinitions,
}: DefinitionItemProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isDescriptionOpened, setIsDescriptionOpened] =
    useState<boolean>(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const titleRef = useRef<string>(definition.title);
  const descriptionRef = useRef<string>(definition.description);

  const prevTitleRef = useRef<string>(definition.title);
  const prevDescriptionRef = useRef<string>(definition.description);

  const [title, setTitle] = useState<string>(definition.title);
  const [description, setDescription] = useState<string>(
    definition.description
  );
  const [checked, setChecked] = useState<boolean>(definition.checked);

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    setChecked(definition.checked);
  }, [definition]);

  const handleCheckedChange = () => {
    setChecked(!checked);

    checked
      ? onItemUnCheck({
          ...definition,
          title: title,
          description: description,
          checked: true,
        })
      : onItemCheck({
          ...definition,
          title: title,
          description: description,
          checked: false,
        });
  };

  const handleCreateDefinition = () => {
    if (!title.trim()) return;

    if (checkDuplicateDefinition(title)) {
      return setIsError(true);
    }

    close();
    createCustomDefinition(title);
    setTitle(title.trim());
  };

  const handleUpdateTitle = (newTitle: string) => {
    if (prevTitleRef.current === newTitle) {
      return;
    }

    if (!newTitle.trim()) return;

    if (checkDuplicateDefinition(newTitle)) {
      return setIsError(true);
    }

    setIsError(false);
    updateDefinition(definition, newTitle, description);
    prevTitleRef.current = newTitle.trim();
    setTitle(newTitle.trim());
  };

  const handleUpdateDescription = (newDescription: string) => {
    if (prevDescriptionRef.current === newDescription) {
      return;
    }

    if (!newDescription.trim()) return;

    if (!isError) {
      updateCheckedDefinitions(title, newDescription);
      updateDefinition(definition, title, newDescription);
      prevDescriptionRef.current = newDescription;
      setDescription(newDescription.trim());
    }
  };

  const handleUpdateDefinitionInDB = () => {
    const definitionToSaveInDB = {
      ...definition,
      title: titleRef.current,
      description: descriptionRef.current,
    };

    updateDefinitionInDB(definitionToSaveInDB);

    setIsDropdownOpened(false);
    setIsDisabled(true);
  };

  const handleTestDropdown = () => {
    setIsDropdownOpened(false);
    setIsDisabled(true);
  };

  return (
    <div
      className={classes.container}
      style={{
        border: definition.checked
          ? '1px solid #3174C2'
          : '1px solid transparent',
      }}
    >
      {isNew ? (
        <>
          <div className={`${classes.titleWrapper} ${classes.newTitle}`}>
            <input
              type="text"
              id="title"
              className={classes.titleInput}
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <div className={classes.options}>
              <div
                className={`${classes.icon} ${classes.dismissIcon}`}
                onClick={close}
              ></div>
              <div
                className={`${classes.icon} ${classes.applyIcon}`}
                onClick={handleCreateDefinition}
              ></div>
            </div>
          </div>

          {isError && (
            <InputError
              errorMessage={UIStrings.definition_item.definition_error}
            />
          )}
        </>
      ) : (
        <>
          <div className={classes.titleWrapper}>
            <input
              type="checkbox"
              id="select"
              className={classes.selectCheckbox}
              checked={checked}
              onChange={handleCheckedChange}
            />
            <input
              type="text"
              id="title"
              className={classes.titleInput}
              value={title}
              onBlur={(e) => handleUpdateTitle(e.target.value)}
              onChange={(e) => {
                setIsDisabled(!e.target.value.trim() || isError);

                titleRef.current = e.target.value;
                setTitle(e.target.value);
              }}
            />

            {isDescriptionOpened ? (
              <div
                className={`${classes.icon} ${classes.arrowDownDescriptionIcon}`}
                onClick={() => setIsDescriptionOpened(!isDescriptionOpened)}
              ></div>
            ) : (
              <div
                className={`${classes.icon} ${classes.arrowUpDescriptionIcon}`}
                onClick={() => setIsDescriptionOpened(!isDescriptionOpened)}
              ></div>
            )}
          </div>

          {isError && (
            <InputError
              errorMessage={UIStrings.definition_item.definition_error}
            />
          )}
        </>
      )}

      {isDescriptionOpened && (
        <div className={classes.descriptionWrapper}>
          <textarea
            placeholder={
              UIStrings.definition_item.definition_description_placeholder
            }
            value={description}
            onBlur={(e) => handleUpdateDescription(e.target.value)}
            onChange={(e) => {
              setIsDisabled(!e.target.value.trim() || isError);

              descriptionRef.current = e.target.value;
              setDescription(e.target.value);
            }}
          ></textarea>
          <div
            style={{ position: 'relative' }}
            aria-disabled={isDisabled}
            className={isDisabled ? classes.disabled : ''}
          >
            <div className={classes.save}>
              <div onClick={handleUpdateDefinitionInDB}>
                {UIStrings.definition_item.definition_save}
              </div>
              <div>
                {isDropdownOpened ? (
                  <div
                    className={`${classes.icon} ${classes.arrowDownDropdownIcon}`}
                    onClick={() => setIsDropdownOpened(!isDropdownOpened)}
                  ></div>
                ) : (
                  <div
                    className={`${classes.icon} ${classes.arrowUpDropdownIcon}`}
                    onClick={() => setIsDropdownOpened(!isDropdownOpened)}
                  ></div>
                )}
              </div>
            </div>

            {isDropdownOpened && (
              <div className={classes.actionsWrapper}>
                <div
                  className={classes.actionItem}
                  onClick={handleTestDropdown}
                >
                  <div>{UIStrings.definition_item.definition_reset}</div>
                  <div
                    className={`${classes.icon} ${classes.refreshIcon}`}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DefinitionItem;
