import { useState } from 'react';
import { Form, useActionData } from 'react-router-dom';
import { Eye20Regular, EyeOff20Regular } from '@fluentui/react-icons';

import { AuthErrors } from 'app/auth';
import * as classes from 'views/Login/Login.scss';
import Button from 'components/Button/Button';
import { UIStrings } from 'app/UIStrings';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const errors: AuthErrors = useActionData();

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img
          src="assets/logo.png"
          alt="Patent Assistant Logo"
          width={250}
          height={40}
        />
      </div>

      <div className={classes.main}>
        <Form method="post" replace>
          <div className={classes.form}>
            <div className={classes.email}>
              <div>{UIStrings.login.email_title}</div>
              <input
                className={classes.emailInput}
                name="email"
                type="email"
                value={email}
                placeholder={UIStrings.login.email_placeholder}
                onChange={(ev) => setEmail(ev.target.value)}
              />

              {errors?.email && (
                <span className={classes.formFieldError}>{errors.email}</span>
              )}
            </div>

            <div
              className={classes.password}
              style={{
                marginBottom:
                  errors?.email || errors?.password || errors?.global
                    ? '11px'
                    : '32px',
              }}
            >
              <div>
                <div>{UIStrings.login.password_title}</div>
                <div
                  className={classes.forgot}
                  onClick={() => console.log('forgot password')}
                >
                  {UIStrings.login.password_forgot}
                </div>
              </div>
              <div className={classes.passwordContainer}>
                <input
                  className={classes.passwordInput}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  placeholder={UIStrings.login.password_placeholder}
                  onChange={(ev) => setPassword(ev.target.value)}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className={classes.show}
                >
                  {showPassword ? <Eye20Regular /> : <EyeOff20Regular />}
                </div>
              </div>

              {errors?.password && (
                <span className={classes.formFieldError}>
                  {errors.password}
                </span>
              )}
            </div>

            {errors?.global && (
              <span className={classes.formGlobalError}>{errors.global}</span>
            )}

            <Button title="Login" color="white" size="big" />
          </div>
        </Form>
      </div>

      <div className={classes.bottom}>
        <div>{UIStrings.login.account_new}</div>
        <div
          className={classes.registration}
          onClick={() => console.log('registration')}
        >
          {UIStrings.login.account_create}
        </div>
      </div>
    </div>
  );
};

export default Login;
