import { useLocation } from 'react-router-dom';
import { ProgressBar } from '@fluentui/react-components';

import * as classes from 'components/Progress/Progress.scss';

type ProgressProps = {
  progress: number;
  message: string;
  code: number;
  canAbort?: boolean;
  abortOperation?: () => void;
};

const Progress = ({
  progress,
  message,
  code,
  canAbort = false,
  abortOperation,
}: ProgressProps) => {
  const location = useLocation();

  const styles = () => {
    let color = '#4F82C8';
    let backgroundColor = '#FFF';

    if (code === 3) {
      color = '#FFF';
      backgroundColor = '#257A28';
    }
    if (code === 2 || code === 4) {
      color = '#FFF';
      backgroundColor = '#D02B49';
    }

    return { color, backgroundColor };
  };

  return (
    <div
      className={classes.container}
      style={{
        bottom: location.pathname === '/index.html/consistency-claims' ? 0 : 62,
      }}
    >
      <ProgressBar value={progress ? progress / 100 : 0} />
      <div className={classes.status} style={styles()}>
        {message ? message : ''}
        {canAbort && code === 1 && (
          <div
            className={`${classes.icon} ${classes.dismissIcon}`}
            onClick={abortOperation}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Progress;
