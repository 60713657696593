/* global Office */

import { redirect } from 'react-router-dom';
import { tempStorage } from './index';
import {
  applyReferences,
  applyAbstracts,
  checkDefinition,
  checkMultipleDefinitions,
  fillAllClaimsDescriptions,
  fillZone,
  finalizeDocumentConsistency,
  generateFigureDescription,
  getDefaultPromiseStatus,
  refreshDefinitions,
  refreshFigures,
  refreshReferences,
  uncheckDefinition,
  uncheckMultipleDefinitions,
  saveDefinitionInDb,
  generateReport,
} from 'engine/addInMethods';
import {
  fetchGetConsistencyList,
  fetchLogin,
  fetchTemplateDocumentContent,
} from 'engine/apiHelpers';
import {
  correctSingleError,
  checkAnyConsistency,
  refreshConsistencyList,
} from 'engine/consistency';
import {
  getDocumentId,
  getDocumentLanguage,
  setDocumentLanguage,
  updateDocumentWithContent,
} from 'engine/documentProxy';
import {
  resetLocalStorage,
  definitionType,
  initLocalStorage,
  setDocumentId,
  testErrorType,
  getLocalStorage,
  initUserLocalStorage,
  getUserLocalStorage,
} from 'engine/localStorageHelper';
import {
  FillZoneType,
  PromiseStatus,
  StatusCodes,
  StatusManager,
} from 'engine/models';
import { UIStrings } from './UIStrings';

const debug = () => {
  console.log('test Word side');
};

const getCommonController = (
  statusManager: StatusManager,
  promiseStatus: PromiseStatus
) => {
  const abort = () => {
    promiseStatus.aborted = true;
    statusManager.setStatusCode(StatusCodes.Aborted);
    statusManager.setProgress(0);
    statusManager.setStatusMessage(UIStrings.statusbar.canceled);
  };

  const controller = { abort };

  return controller;
};

const initDocument = async () => {
  console.log('initDocument');
  console.log('Set API language: ' + tempStorage.apiLanguage);
  tempStorage.totalTerms.length = 0;
  tempStorage.origTerms.length = 0;
  tempStorage.definitionsFirstInsert = true;

  const id = await getDocumentId();
  const lang = await getDocumentLanguage();
  console.log('Get document language: ' + lang);
  tempStorage.apiLanguage = lang ? lang : 'en';
  console.log('Set API language: ' + tempStorage.apiLanguage);
  setDocumentId(id);
  console.log('document id: ' + id);

  const localStorage = getLocalStorage();
  const userLocalStorage = getUserLocalStorage();

  if (!userLocalStorage) {
    initUserLocalStorage();
  }

  if (!localStorage) {
    initLocalStorage();
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const initDocumentWithTemplate = async (
  templateName: string,
  language: string
) => {
  resetLocalStorage();
  await updateDocumentWithContent('');
  console.log('templateName', templateName);
  const templateResponse = await fetchTemplateDocumentContent(templateName);
  console.log('initDocumentWithTemplate ' + tempStorage);
  if (!templateResponse.error) {
    await updateDocumentWithContent(templateResponse.template);
    console.log('Set API language: ' + tempStorage.apiLanguage);
    tempStorage.apiLanguage = language;
    await setDocumentLanguage(language);
    await refreshConsistencyList();
  } else {
    redirect('/index.html');
  }
};

const fillAllZonesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  promiseStatus.bulkTaskCount = 4;
  statusManager.currentTaskId = 0;
  statusManager.bulkTaskCount = 4;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    fillZone(statusManager, FillZoneType.Title, promiseStatus, resolve)
      .then(() => {
        promiseStatus.currentTaskId = 1;
        statusManager.currentTaskId = 1;
        return fillZone(
          statusManager,
          FillZoneType.SummaryTop,
          promiseStatus,
          resolve
        );
      })
      .then(() => {
        promiseStatus.currentTaskId = 2;
        statusManager.currentTaskId = 2;
        return fillAllClaimsDescriptions(statusManager, promiseStatus, resolve);
      })
      .then(() => {
        promiseStatus.currentTaskId = 3;
        statusManager.currentTaskId = 3;
        return fillZone(
          statusManager,
          FillZoneType.SummaryBottom,
          promiseStatus,
          resolve
        );
      });
  });

  return controller;
};

const fillAllClaimsDescriptionsPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  statusManager.currentTaskId = 2;

  new Promise((resolve) => {
    fillAllClaimsDescriptions(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const fillZonePromise = (
  statusManager: StatusManager,
  fillZoneType: FillZoneType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    fillZone(statusManager, fillZoneType, promiseStatus, resolve);
  });

  return controller;
};

const saveDefinitionInDbPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    saveDefinitionInDb(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

const refreshDefinitionsPromise = (statusManager: StatusManager) => {
  const promiseStatus: PromiseStatus = {
    progress: 0,
    aborted: false,
    bulkTaskCount: 1,
    currentTaskId: 0,
  };

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshDefinitions(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const checkDefinitionPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    checkDefinition(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

const uncheckDefinitionPromise = (
  statusManager: StatusManager,
  definition: definitionType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    uncheckDefinition(statusManager, definition, promiseStatus, resolve);
  });

  return controller;
};

const checkMultipleDefinitionsPromise = (
  statusManager: StatusManager,
  definitions: definitionType[]
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    checkMultipleDefinitions(
      statusManager,
      definitions,
      promiseStatus,
      resolve
    );
  });

  return controller;
};

const uncheckMultipleDefinitionsPromise = (
  statusManager: StatusManager,
  definitions: definitionType[]
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    uncheckMultipleDefinitions(
      statusManager,
      definitions,
      promiseStatus,
      resolve
    );
  });

  return controller;
};

const refreshReferencesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshReferences(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const applyReferencesPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    applyReferences(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const applyAbstractsPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    applyAbstracts(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const refreshFiguresPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshFigures(statusManager, promiseStatus, resolve);
  });

  return controller;
};

const generateFigureDescriptionPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  promiseStatus.bulkTaskCount = 2;
  statusManager.currentTaskId = 0;
  statusManager.bulkTaskCount = 2;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    generateFigureDescription(statusManager, promiseStatus, resolve).then(
      () => {
        promiseStatus.currentTaskId = 1;
        statusManager.currentTaskId = 1;
        return applyReferences(statusManager, promiseStatus, resolve);
      }
    );
  });

  return controller;
};

const finalizeDocumentConsistencyPromise = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    finalizeDocumentConsistency(statusManager, promiseStatus, resolve).then(
      () => {
        return generateReport(statusManager, promiseStatus, resolve);
      }
    );
  });

  return controller;
};

const rescanSingleTestErrorsPromise = (
  statusManager: StatusManager,
  testName: string
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    promiseStatus.bulkTaskCount = 1;
    statusManager.currentTaskId = 0;
    statusManager.bulkTaskCount = 1;
    checkAnyConsistency(statusManager, testName, promiseStatus, resolve);
  });

  return controller;
};

const rescanAllTestsErrorsPromise = (
  statusManager: StatusManager,
  claimsStage: boolean
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();

  const filteredConsistencyList =
    claimsStage === true
      ? tempStorage.consistencyList.filter((c) => c.target === 'claims')
      : tempStorage.consistencyList.filter((c) => c.target !== 'claims');

  promiseStatus.currentTaskId = 0;
  statusManager.currentTaskId = 0;
  promiseStatus.bulkTaskCount = filteredConsistencyList.length;
  statusManager.bulkTaskCount = promiseStatus.bulkTaskCount;

  statusManager.setProgress(promiseStatus.progress);
  statusManager.setStatusCode(StatusCodes.InProgress);
  statusManager.setStatusMessage(UIStrings.statusbar.checking_claims_progress);

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise(async (resolve) => {
    let index = 0;
    for (const consItem of filteredConsistencyList) {
      promiseStatus.currentTaskId = index;
      statusManager.currentTaskId = index;
      try {
        await checkAnyConsistency(
          statusManager,
          consItem.testKey,
          promiseStatus,
          resolve
        );
      } catch (error) {
        console.error(
          `Error occurred while checking consistency for ${consItem.testDisplayName}:`,
          error
        );
        break;
      }
      index++;
    }
    statusManager.setProgress(100);
  });

  return controller;
};

const initialRefresh = (statusManager: StatusManager) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();

  promiseStatus.currentTaskId = 0;
  statusManager.currentTaskId = 0;
  promiseStatus.bulkTaskCount = 2;
  statusManager.bulkTaskCount = 2;

  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    refreshReferences(statusManager, promiseStatus, resolve).then(() => {
      promiseStatus.currentTaskId = 1;
      statusManager.currentTaskId = 1;
      return refreshDefinitions(statusManager, promiseStatus, resolve);
    });
  });

  return controller;
};

const correctSingleErrorPromise = (
  statusManager: StatusManager,
  error: testErrorType
) => {
  let promiseStatus: PromiseStatus = getDefaultPromiseStatus();
  const controller = getCommonController(statusManager, promiseStatus);

  new Promise((resolve) => {
    correctSingleError(statusManager, error, promiseStatus, resolve);
  });

  return controller;
};

export {
  debug,
  initDocument,
  initDocumentWithTemplate,
  fillAllClaimsDescriptionsPromise,
  fillZonePromise,
  fillAllZonesPromise,
  saveDefinitionInDbPromise,
  refreshDefinitionsPromise,
  checkDefinitionPromise,
  uncheckDefinitionPromise,
  checkMultipleDefinitionsPromise,
  uncheckMultipleDefinitionsPromise,
  refreshReferencesPromise,
  applyReferencesPromise,
  applyAbstractsPromise,
  refreshFiguresPromise,
  generateFigureDescriptionPromise,
  finalizeDocumentConsistencyPromise,
  rescanSingleTestErrorsPromise,
  rescanAllTestsErrorsPromise,
  initialRefresh,
  correctSingleErrorPromise,
};
