import { statusType } from 'engine/localStorageHelper';
import * as classes from 'components/NavigationBar/NavigationBar.scss';
import NavigationLink from 'components/NavigationLink/NavigationLink';

type NavigationBarProps = {
  status: statusType;
};

const NavigationBar = ({ status }: NavigationBarProps) => {
  return (
    <div className={classes.container}>
      <NavigationLink to="reference" status={status.references} />
      <NavigationLink to="figures" status={status.figures} />
      <NavigationLink to="generation" status={status.generation.tabStatus} />
      <NavigationLink to="definition" status={status.definitions} />
      <NavigationLink to="consistency" status={status.consistencyErrorsCount} />
      <NavigationLink to="settings" />
    </div>
  );
};

export default NavigationBar;
