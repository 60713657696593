import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import { fetchGetTermsFiltered } from 'engine/apiHelpers';
import * as classes from 'components/Search/Search.scss';
import { useNavigate } from 'react-router-dom';

type Definition = {
  term: string;
  description: string;
};

type SearchProps = {
  placeholder: string;
  addDefinition?: (title: string, description: string) => void;
};

const Search = ({ addDefinition, placeholder }: SearchProps) => {
  const navigate = useNavigate();

  const searchInput = useRef<HTMLInputElement>(null);
  const [timer, setTimer] = useState(null);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [definitions, setDefinitions] = useState<Definition[]>([]);

  useEffect(() => {
    if (!searchTerm.trim()) return setDefinitions([]);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      searchDefinitions(searchTerm.trim());
    }, 500);

    setTimer(newTimer);
  }, [searchTerm]);

  const searchDefinitions = async (value: string) => {
    const result = await fetchGetTermsFiltered(value);
    if (result.httpCode === 401) {
      return navigate('/index.html', { replace: true });
    }
    const definitions: Definition[] = result.definitions;

    setDefinitions(definitions);
  };

  const handleInputClick = () => {
    searchInput.current.focus();
  };

  const handleKeyClick = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      setSearchTerm('');
      setDefinitions([]);
    }
  };

  const handleAddDefinition = (definition: Definition) => {
    setSearchTerm('');
    setDefinitions([]);

    addDefinition(definition.term, definition.description);
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.searchWrapper}
        onClick={handleInputClick}
        onKeyDown={handleKeyClick}
      >
        <div className={`${classes.icon} ${classes.searchIcon}`}></div>
        <input
          className={classes.searchInput}
          placeholder={placeholder}
          ref={searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {definitions.length > 0 && (
        <div className={classes.resultWrapper}>
          {definitions.map((definition: Definition, index: number) => (
            <div
              key={definition.term + index}
              className={classes.resultItem}
              onClick={() => handleAddDefinition(definition)}
            >
              {definition.term}
              <div className={`${classes.icon} ${classes.addIcon}`}></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Search;
