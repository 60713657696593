import LocalizedStrings from 'react-localization';

export const UIStrings = new LocalizedStrings({
  en: {
    login: {
      login_title: 'Welcome back to Witchdraft!',
      email_title: 'E-Mail',
      email_placeholder: 'Please enter your e-mail address',
      password_title: 'Password',
      password_forgot: 'Forgot password?',
      password_placeholder: 'Please enter your password',
      account_new: "Don't have an account?",
      account_create: 'Register now',
    },
    auth: {
      invalid_email: 'Invalid Email',
      invalid_password: 'Invalid Password',
      invalid_credentials: 'Invalid Credentials',
      invalid_login: 'Invalid Login Attempt',
    },
    templates: {
      template_title: 'Template Selection',
      template_description:
        'Select the document template that you require for further work or continue with the current document.',
      continue: 'Continue document',
      choose_template: 'Or select a template',
    },
    patent_formula: {
      patent_formula_description:
        'Now please enter the patent claims in the designated place in the template!',
      check_claims: 'Check claims',
      back_button: 'Back to templates',
    },
    consistency_claims: {
      consistency_title: 'Consistency',
      consistency_description_errors:
        'Errors were found in the data you entered. Correct them and click on the “Update” button to continue.',
      consistency_description_noerrors:
        'No errors were found in the data you entered.',
      continue_button: 'Continue',
      back_button: 'Back to templates',
    },
    consistency_global: {
      consistency_title: 'Consistency',
      consistency_description_1: 'The consistency check currently identifies',
      consistency_description_2:
        'inconsistencies - please check these to improve the quality of your patent application.',
      consistency_check: 'Check consistency',
      refresh: 'Refresh',
      finalize_document: 'Finalize document',
    },
    consistency_item: {
      accept: 'Accept',
      ignore: 'Ignore',
      skip_reason_placeholder: 'Enter reason',
    },
    references: {
      references_title: 'References',
      references_description: 'Manage your list of references here.',
      references_empty:
        'You have not yet selected any terms. Select terms from the suggestions or add terms from the text by right-clicking.',
      references_renumber: 'Renumber',
      references_add: 'Add term',
      reference_error: 'A reference with this number already exists!',
      suggestions_title: 'Suggestions',
      add_to_document: 'Add to document',
    },
    figures: {
      figures_title: 'Figures',
      figures_description:
        'Manage your figures here. Each figure can be given a short description text and claims can be assigned before a figure description can be automatically generated based on this information.',
      figures_add: 'Add',
      figures_generate: 'Generate',
    },
    figure_item: {
      figure_description_placeholder:
        'Enter a short description of the figure here...',
    },
    text_generation: {
      text_generation_title: 'Text Generation',
      text_generation_description:
        'Automatically generate parts of the patent application based on the patent claims you have inserted.',
      generate_all: 'Generate all parts',
      text_generation_divider: 'Or generate individual parts',
      generate_title: 'Generate title',
      generate_intro: 'Generate introduction',
      generate_description: 'Generate common part',
      generate_summary: 'Generate summary',
    },
    definitions: {
      definitions_title: 'Definitions',
      definitions_description:
        'Insert definitions from your database here, change them or add new ones.',
      db_search: 'Search in database...',
      definition_add: 'Add new definition',
      add_to_document: 'Add to document',
    },
    definition_item: {
      definition_description_placeholder: 'Please write a description here',
      definition_save: 'Save',
      definition_reset: 'Reset',
      definition_error: 'A definition with this name already exists!',
    },
    settings: {
      settings_title: 'Settings',
      settings_description:
        'Insert definitions after first occurrence of the term',
      settings_max_claims: 'Maximum patent claims',
    },
    statusbar: {
      checking_references_progress: 'Checking references in progress.',
      checking_references_completed: 'Checking references completed.',
      checking_references_failed: 'Checking references failed.',
      checking_claims_progress: 'Checking claims in progress:',
      checking_claims_completed: 'Checking claims completed:',
      checking_claims_error: 'Error checking claims.',
      canceled: 'Canceled',
      unauthorized: 'Unauthorized Access',
      description_created: 'Patent description created',
      description_failed: 'Creation of patent description failed',
      all_operations_completed: 'All operations completed',
      inserting_definitions: 'Definitions are being inserted...',
      definition_saved: 'Definition Saved',
      definition_save_failed: 'Saving Definition failed',
      updating_definition: 'Definitions are being updated...',
      definitions_updated: 'Definitions updated',
      refresh_failed: 'Refresh Failed',
      definitions_added: 'Definitions added',
      definitions_insert_failed: 'Inserting definitions failed',
      definition_not_found: 'Definition not found',
      removing_definition: 'Definition is being removed...',
      removing_definitions: 'Definitions are being removed...',
      definition_removed: 'Definition removed',
      definitions_removed: 'Definitions removed',
      remove_failed: 'Removal failed',
      refresh_references: 'Reference numbers are being updated...',
      references_updated: 'Reference numbers updated',
      inserting_references: 'Reference numbers are being inserted...',
      insert_failed: 'Insert failed',
      references_inserted: 'Reference number inserted',
      generating_descriptions: 'Generating Patent description...',
      generating_abstracts: 'Abstracts are generated...',
      abstract_generated: 'Abstract generated',
      generation_failed: 'Generation failed',
      updating_figures: 'Updating figures',
      figures_updated: 'Figures updated',
      generating_figures: 'Generating figures...',
      figures_generated: 'Figures generated',
      finalizing: 'Finalizing...',
      finalized: 'Finalized',
      error_corrected: 'Error corrected',
      generating_report: 'Generating report',
      report_generated: 'Report generated',
    },
    tasks: {
      intro_title: 'Introduction',
      intro_progress: 'Generating introduction...',
      intro_completed: 'Introduction created.',
      intro_failed: 'Introduction creation failed.',
      summary_title: 'Summary',
      summary_progress: 'Generating summary...',
      summary_completed: 'Summary created.',
      summary_failed: 'Summary creation failed.',
      title_title: 'Title',
      title_progress: 'Title generation...',
      title_completed: 'Title generated.',
      title_failed: 'Title generation failed.',
    },
    final: {
      final_document: 'Document finalized - now you can close the Add-In.',
      download_pdf: 'Download PDF-Report',
    },
  },
  de: {
    login: {
      login_title: 'Willkommen zurück bei Witchdraft!',
      email_title: 'E-Mail',
      email_placeholder: 'Bitte geben sie ihre E-Mail Adresse ein',
      password_title: 'Passwort',
      password_forgot: 'Passwort vergessen?',
      password_placeholder: 'Passwort eingeben',
      account_new: 'Sie haben kein Konto?',
      account_create: 'Jetzt registrieren',
    },
    auth: {
      invalid_email: 'Ungültige E-Mail',
      invalid_password: 'Ungültiges Passwort',
      invalid_credentials: 'Ungültige Berechtigungsnachweise',
      invalid_login: 'Ungültiger Login-Versuch',
    },
    templates: {
      template_title: 'Auswahl einer Vorlage',
      template_description:
        'Wählen Sie die Dokumentvorlage aus, die Sie für die weitere Arbeit damit benötigen oder fahren Sie mit dem aktuellen Dokument fort.',
      continue: 'Dokument fortsetzen',
      choose_template: 'Oder wählen Sie eine Vorlage',
    },
    patent_formula: {
      patent_formula_description:
        'Geben sie jetzt bitte die Patentansprüche an die entsprechende Stelle in der Vorlage ein!',
      check_claims: 'Ansprüche überprüfen',
      back_button: 'Zurück zu den Vorlagen',
    },
    consistency_claims: {
      consistency_title: 'Konsistenz',
      consistency_description_errors:
        'In den von Ihnen eingegebenen Daten wurden Fehler gefunden. Korrigieren Sie diese und klicken Sie auf die Schaltfläche „Aktualisieren“, um fortzufahren.',
      consistency_description_noerrors:
        'In den von Ihnen eingegebenen Daten wurden keine Fehler gefunden.',
      continue_button: 'Fortfahren',
      back_button: 'Zurück zu den Vorlagen',
    },
    consistency_global: {
      consistency_title: 'Konsistenz',
      consistency_description_1: 'Die Konsistenzprüfung ermittelt aktuell noch',
      consistency_description_2:
        'Inkonsistenzen - bitte prüfen sie diese, um die Qualität ihrer Patentanmeldung zu verbessern.',
      consistency_check: 'Konsistenzprüfung ausführen',
      refresh: 'Aktualisieren',
      finalize_document: 'Dokument finalisieren',
    },
    consistency_item: {
      accept: 'Annehmen',
      ignore: 'Ignorieren',
      skip_reason_placeholder: 'Grund eingeben',
    },
    references: {
      references_title: 'Bezugszeichenliste',
      references_description: 'Verwalten sie hier ihre Liste an Bezugszeichen.',
      references_empty:
        'Sie haben noch keine Begriffe ausgewählt. Wählen sie Begriffe aus den Vorschlägen, oder fügen sie Begriffe aus dem Text via Rechtsklick hinzu.',
      references_renumber: 'Neu nummerieren',
      references_add: 'Bezugszeichen hinzufügen',
      reference_error: 'Ein Bezugszeichen mit dieser Nummer existiert bereits!',
      suggestions_title: 'Vorschläge',
      add_to_document: 'Zum Dokument hinzufügen',
    },
    figures: {
      figures_title: 'Figuren',
      figures_description:
        'Verwalten sie hier ihre Figuren. Jeder Figur kann ein kurzer Beschreibungstext gegeben und Ansprüche zugewiesen werden, bevor auf dieser Basis automatisch eine Figurenbeschreibung generiert werden kann.',
      figures_add: 'Hinzufügen',
      figures_generate: 'Generieren',
    },
    figure_item: {
      figure_description_placeholder:
        'Geben sie hier eine kurze Beschreibung der Figur ein...',
    },
    text_generation: {
      text_generation_title: 'Text Generation',
      text_generation_description:
        'Generieren sie hier automatisch Teile der Patentanmeldung auf Basis der von Ihnen eingefügten Patentansprüche.',
      generate_all: 'Alle Teile generieren',
      text_generation_divider: 'Oder Einzelteile generieren',
      generate_title: 'Titel generieren',
      generate_intro: 'Einleitung generieren',
      generate_description: 'Allgemeinen Teil generieren',
      generate_summary: 'Zusammenfassung generieren',
    },
    definitions: {
      definitions_title: 'Definitionen',
      definitions_description:
        'Fügen sie hier Definitionen aus ihrer Datenbank ein, ändern sie diese oder fügen neue hinzu.',
      db_search: 'In Datenbank suchen...',
      definition_add: 'Neue Definition hinzufügen',
      add_to_document: 'Zum Dokument hinzufügen',
    },
    definition_item: {
      definition_description_placeholder:
        'Schreiben Sie hier eine Beschreibung',
      definition_save: 'Speichern',
      definition_reset: 'Zurücksetzen',
      definition_error: 'Ein Definitionen mit diesem Namen existiert bereits',
    },
    settings: {
      settings_title: 'Einstellungen',
      settings_description:
        'Definitionen nach erstem Auftreten des Begriffs einfügen',
      settings_max_claims: 'Maximale Patentansprüche',
    },
    statusbar: {
      checking_references_progress: 'Bezugszeichen werden überprüft...',
      checking_references_completed: 'Bezugszeichen prüfen abgeschlossen',
      checking_references_failed:
        'Überprüfung der Bezugszeichen fehlgeschlagen',
      checking_claims_progress: 'Prüfung läuft:',
      checking_claims_completed: 'Prüfung abgeschlossen:',
      checking_claims_error: 'Fehler bei der Überprüfung von Ansprüchen.',
      canceled: 'Abgebrochen',
      unauthorized: 'Unautorisierter Zugriff',
      description_created: 'Patentbeschreibung erstellt',
      description_failed: 'Erstellung der Patentbeschreibung fehlgeschlagen',
      all_operations_completed: 'Alle Operationen abgeschlossen',
      inserting_definitions: 'Definitionen werden eingefügt...',
      definition_saved: 'Definition gespeichert',
      definition_save_failed: 'Speichern der Definition fehlgeschlagen',
      updating_definition: 'Definitionen werden aktualisiert...',
      definitions_updated: 'Definitionen aktualisiert',
      refresh_failed: 'Aktualisierung fehlgeschlagen',
      definitions_added: 'Definitionen eingefügt',
      definitions_insert_failed:
        'Das Einfügen der Definitionen ist fehlgeschlagen',
      definition_not_found: 'Definition nicht gefunden',
      removing_definition: 'Definition werden entfernt...',
      removing_definitions: 'Definitionen werden entfernt...',
      definition_removed: 'Definition entfernt',
      definitions_removed: 'Definitionen entfernt',
      remove_failed: 'Entfernen fehlgeschlagen',
      refresh_references: 'Bezugszeichen werden aktualisiert...',
      references_updated: 'Bezugszeichen aktualisiert',
      inserting_references: 'Bezugszeichen werden eingefügt...',
      insert_failed: 'Einfügen fehlgeschlagen',
      references_inserted: 'Bezugszeichen eingefügt',
      generating_descriptions: 'Patentbeschreibung wird generiert...',
      generating_abstracts: 'Abstrakte werden generiert...',
      abstract_generated: 'Abstrakte generiert',
      generation_failed: 'Generation failed',
      updating_figures: 'Figurenbeschreibungen werden aktualisiert...',
      figures_updated: 'Figurenbeschreibungen aktualisiert',
      generating_figures: 'Figurenbeschreibungen werden generiert...',
      figures_generated: 'Figurenbeschreibungen generiert',
      finalizing: 'Abschließen',
      finalized: 'Abgeschlossen',
      error_corrected: 'Fehler behoben',
      generating_report: 'Bericht erstellen',
      report_generated: 'Bericht erstellt',
    },
    tasks: {
      intro_title: 'Einleitung',
      intro_progress: 'Einleitung wird generiert...',
      intro_completed: 'Einleitung erstellt.',
      intro_failed: 'Erstellung der Einleitung fehlgeschlagen.',
      summary_title: 'Zusammenfassung',
      summary_progress: 'Zusammenfassung wird generiert...',
      summary_completed: 'Zusammenfassung erstellt.',
      summary_failed: 'Erstellung der Zusammenfassung fehlgeschlagen.',
      title_title: 'Titel',
      title_progress: 'Titelgenerierung...',
      title_completed: 'Titel generiert.',
      title_failed: 'Titelgenerierung fehlgeschlagen.',
    },
    final: {
      final_document: 'Document finalized - now you can close the Add-In.',
      download_pdf: 'PDF-Bericht herunterladen',
    },
  },
});
