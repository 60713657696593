enum StatusCodes {
  Idle,
  InProgress,
  Fail,
  Success,
  Aborted,
  Unauthorized,
}

enum FillZoneType {
  Title,
  SummaryTop,
  SummaryBottom,
}

interface ConsistencyMessage {
  id: string;
  text: string;
  include: boolean;
}

interface ConsistencyInstructionItem {
  modelType: string;
  testKey: string;
  consistencyType: string;
  target: string;
  testDisplayName: string;
  messages: ConsistencyMessage[];
  language: string;
}

interface LoginResponse {
  accessToken: string;
  backend: string;
  expiresIn: number;
  refreshToken: string;
  refreshExpiresIn: number;
  error: string;
  httpCode: number;
  statusMessage: string;
}

const defaultLoginResponse: LoginResponse = {
  accessToken: '',
  backend: '',
  expiresIn: 0,
  refreshToken: '',
  refreshExpiresIn: 0,
  error: '',
  httpCode: 0,
  statusMessage: '',
};

interface UserInfoResponse {
  sub: string;
  backend: string;
  emailVerified: boolean;
  name: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  email: string;
  error: string;
  httpCode: number;
  statusMessage: string;
}

const defaultUserInfoResponse: UserInfoResponse = {
  sub: '',
  backend: '',
  emailVerified: false,
  name: '',
  preferredUsername: '',
  givenName: '',
  familyName: '',
  email: '',
  error: '',
  httpCode: 0,
  statusMessage: '',
};

interface StatusManager {
  setProgress: (a: number) => void;
  setStatusCode: (s: StatusCodes) => void;
  setStatusMessage: (s: string) => void;
  bulkTaskCount?: number;
  currentTaskId?: number;
}

interface PromiseStatus {
  progress: number;
  aborted: boolean;
  bulkTaskCount: number;
  currentTaskId: number;
}

const defaultPromiseStatus: PromiseStatus = {
  progress: 0,
  aborted: false,
  bulkTaskCount: 1,
  currentTaskId: 0,
};

export {
  StatusCodes,
  FillZoneType,
  StatusManager,
  PromiseStatus,
  defaultPromiseStatus,
  LoginResponse,
  defaultLoginResponse,
  UserInfoResponse,
  defaultUserInfoResponse,
  ConsistencyInstructionItem,
};
