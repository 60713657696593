import { useEffect, useState } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import {
  DismissFilled,
  CheckmarkFilled,
  PortUsbCFilled,
} from '@fluentui/react-icons';

import * as classes from 'components/NavigationLink/NavigationLink.scss';

type CustomLinkProps = {
  to:
    | 'generation'
    | 'definition'
    | 'reference'
    | 'figures'
    | 'consistency'
    | 'settings';
  status?: number;
};

const NavigationLink = ({ to, status }: CustomLinkProps) => {
  const resolved = useResolvedPath(`/index.html/${to}`);
  const match = useMatch({ path: resolved.pathname, end: true });

  const [color, setColor] = useState('#92ACDC');

  useEffect(() => {
    changeColor();
  }, [status]);

  const linkIconClassName = to + 'Icon';

  const changeColor = () => {
    if (status === 0) {
      return setColor('#92ACDC');
    }

    if (status > 0 && status < 100) {
      return setColor('#FED955');
    }

    if (status === 100) {
      return setColor('#4BC14F');
    }

    return setColor('#FF8383');
  };

  return (
    <div
      className={classes.link}
      style={{ backgroundColor: match ? '#18599B' : 'transparent' }}
    >
      <NavLink to={resolved.pathname}>
        <div
          className={`${classes.icon} ${classes[linkIconClassName]}`}
          style={{ background: match ? '#FFF' : '#BAC9E9' }}
        ></div>

        {/* Status Badge For Tabs */}
        {to !== 'settings' && to !== 'consistency' && (
          <div
            className={classes.statusBadge}
            style={{ backgroundColor: color }}
          >
            {status === 0 && <PortUsbCFilled primaryFill="#1B4C82" />}
            {status > 0 && status < 100 && (
              <div style={{ color: '#1B4C82' }}>~</div>
            )}
            {status === 100 && <CheckmarkFilled primaryFill="#1B4C82" />}
          </div>
        )}

        {/* Status Badge For Consistency */}
        {to === 'consistency' && status > 0 && (
          <div
            className={classes.statusBadge}
            style={{ backgroundColor: '#FF8383' }}
          >
            <DismissFilled primaryFill="#1B4C82 " />
          </div>
        )}
      </NavLink>
    </div>
  );
};

export default NavigationLink;
